import { Icon } from "@iconify/react";
import { Form, Formik, FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Input, Label, TextArea } from "../../components/shared/InputField";
import { Select } from "../../components/shared/Select";
import {
  Box,
  ButtonPrimary,
  ButtonSecondary,
  Center,
  Error,
  HStack,
  VStack,
} from "../../components/utils";
import {
  useEditEmployee,
  useEmployeePageDetails,
} from "../../queries/employees";
import { useError } from "../../store/errorStore";
import { usePermissionStore } from "../../store/permissionStore";
import {
  AddEmployeeReq,
  EmployementStatus,
  EmployementType,
} from "../../types/Employee";
import { determineUserAccessToResource } from "../../utils/auth";
import convertToBase64 from "../../utils/convertToBase64";
import { formatDate, formatWithTimeZone } from "../../utils/date";
import useIsMobile from "../../utils/detectDevice";
import {
  Action as DefaultAction,
  Resource,
} from "../../utils/interfaces/Companies";

function Profile() {
  const { permission } = usePermissionStore();

  const [disableState, setDisabledState] = useState(false);
  const { id } = useParams();
  const _id = id ?? "";
  const errorMessage = useError();
  const { data: employeePageDetail } = useEmployeePageDetails(_id);
  useEffect(() => {
    if (
      employeePageDetail?.status &&
      [
        EmployementStatus.RESIGNED,
        EmployementStatus.TERMINATED,
        EmployementStatus.RETIRED,
      ].includes(employeePageDetail.status)
    )
      setDisabledState(true);
  }, [employeePageDetail]);
  useEffect(() => {
    setImageUploadURL("");
  }, [id]);
  const { mutate: addEmployee, isLoading } = useEditEmployee();
  const [imageUploadURL, setImageUploadURL] = useState(
    employeePageDetail && employeePageDetail?.imageURL
  );
  const initialValues: AddEmployeeReq = {
    employeeName: employeePageDetail?.employeeName ?? "",
    employeeDesignation: employeePageDetail?.designation ?? "",
    contactNumber: employeePageDetail?.contactNumber ?? "",
    department: employeePageDetail?.department ?? "",
    dateOfJoin: formatDate(
      new Date(employeePageDetail?.dateOfJoin || "2001-1-1").toUTCString() ||
        new Date("2001-1-1").toUTCString()
    ),
    imageURL: employeePageDetail?.imageURL,
    pan: employeePageDetail?.pan ?? "",
    id: employeePageDetail?.id ?? "",
    address: employeePageDetail?.address ?? "",
    email: employeePageDetail?.emailAddress ?? "",
    manager: employeePageDetail?.manager ?? "",
    residentialStatus: employeePageDetail?.residentStatus ?? "",
    legalName: employeePageDetail?.employeeName ?? "",
    nationality: employeePageDetail?.nationality ?? "",
    grade: employeePageDetail?.grade ?? "",
    employeeIdentification: employeePageDetail?.employeeIdentification ?? "",
    employmentStatus: employeePageDetail?.status ?? EmployementStatus.EMPLOYED,
    company: employeePageDetail?.employerName ?? "",
    employmentType:
      employeePageDetail?.employmentType ?? EmployementType.EMPLOYEE,
    resignationDate: employeePageDetail?.resignationDate
      ? formatDate(employeePageDetail?.resignationDate)
      : undefined,
    lastDay:
      employeePageDetail?.status === EmployementStatus.RESIGNED
        ? formatDate(employeePageDetail?.lastDay) || undefined
        : undefined,
    notes: employeePageDetail?.notes ?? "",
    personalEmail: employeePageDetail?.personalEmail ?? "",
    dematAccountId: employeePageDetail?.dematAccountId ?? "",
  };
  const validationSchema = () =>
    Yup.object().shape({
      employeeName: disableState
        ? Yup.string()
        : Yup.string().required("Name is required").min(3, "Minimum 3 letters"),
      contactNumber: Yup.string()
        .min(8, "Minimum 8 digits are required")
        .matches(
          /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im,
          "Invalid Contact Number"
        ),
      dateOfJoin: disableState
        ? Yup.string()
        : Yup.string().required("Date of joining is required"),
      employeeIdentification: disableState
        ? Yup.string()
        : Yup.string().required("Employee Identification ID is required"),
      employeeDesignation: disableState
        ? Yup.string()
        : Yup.string().required("Designation is required"),
      department: disableState
        ? Yup.string()
        : Yup.string().required("Department is required"),
      employmentType: disableState
        ? Yup.string()
        : Yup.string().required("Employment Type is required"),
    });

  const handleFormSubmit = (values: AddEmployeeReq) => {
    const editEmployeeDto = { ...values };
    editEmployeeDto.dateOfJoin = formatWithTimeZone(editEmployeeDto.dateOfJoin);
    if (
      editEmployeeDto.employmentStatus === EmployementStatus.RESIGNED &&
      editEmployeeDto.lastDay !== undefined
    ) {
      editEmployeeDto.lastDay = formatWithTimeZone(
        editEmployeeDto.lastDay || "2001-1-1"
      );
    }
    editEmployeeDto.email = editEmployeeDto.email.trim();
    addEmployee(editEmployeeDto, {
      onSuccess: () => {
        toast("Employee Details Edited Successfully!", { type: "success" });
      },
      onError: (err: any) => {
        errorMessage.setMessage(err.response.data.reason);
        toast(err.response.data.reason, { type: "error", autoClose: 2000 });
      },
    });
  };
  async function handleEditImage(
    e: React.ChangeEvent<HTMLInputElement>,
    formik: FormikProps<AddEmployeeReq>
  ) {
    if (!e?.target?.files?.[0]) return;
    const file = e.target.files[0];
    const base64: string = (await convertToBase64(file, true)) as string;
    setImageUploadURL(base64);
    formik.setFieldValue("imageURL", base64);
  }
  const { isMobile } = useIsMobile();
  return (
    <>
      <Box className="my-6 bg-white">
        <HStack className="items-baseline justify-between p-6">
          <Box className="py-2 text-lg font-semibold text-gray-600 ">
            Profile Details
          </Box>
        </HStack>
        <Box className="w-full border-b"></Box>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values) => {
            handleFormSubmit(values);
          }}
          validationSchema={validationSchema}
        >
          {(formik) => (
            <>
              <Form>
                <Box
                  className={`${
                    isMobile
                      ? "m-6"
                      : "flex-1 m-6 flex-row md:justify-start md:flex"
                  }`}
                >
                  <Label className="justify-start px-5 py-2 text-sm font-medium text-gray-600 min-w-min w-60">
                    Avatar
                  </Label>
                  <VStack className="gap-4">
                    <Box className="relative w-32">
                      <Label>
                        <input
                          type="file"
                          accept="image/png, image/jpg, image/jpeg"
                          className="hidden"
                          disabled={disableState}
                          onChange={(e) => handleEditImage(e, formik)}
                        />
                        <Icon
                          icon={"eva:edit-outline"}
                          className={`${
                            disableState ? "cursor-not-allowed" : ""
                          } absolute z-10 w-6 h-6 text-gray-400 bg-gray-100 rounded-full cursor-pointer -right-3 hover:text-black`}
                        />
                      </Label>
                      {formik.values.imageURL === null ||
                      formik.values.imageURL === "" ? (
                        <Center className="relative w-32 h-32 bg-gray-100 ">
                          <Center className="flex w-32 h-32 rounded-full ">
                            <p className="text-base font-bold ">
                              {formik.values.employeeName.charAt(0)}
                            </p>
                          </Center>
                        </Center>
                      ) : (
                        <img
                          className="block w-32 h-32 space-y-3 bg-gray-400 border-2 rounded-lg"
                          src={formik.values.imageURL}
                          alt="avatar"
                        />
                      )}

                      <Icon
                        icon={"eva:close-fill"}
                        onClick={() => {
                          setImageUploadURL("");
                        }}
                        className={`${
                          disableState ? "cursor-not-allowed" : ""
                        } absolute z-10 w-6 h-6 text-gray-400 bg-gray-100 rounded-full cursor-pointer -right-3 -bottom-3 hover:text-black`}
                      />
                    </Box>
                    <Box className="text-gray-400">
                      Allowed file types: png, jpg, jpeg.
                    </Box>
                  </VStack>
                </Box>
                <Box
                  className={`${
                    isMobile
                      ? "m-6"
                      : "flex-1 m-6 flex-row md:justify-start md:flex"
                  }`}
                >
                  <Label className="px-5 py-2 text-sm font-medium text-gray-600 min-w-min w-60">
                    Name
                  </Label>
                  <Box>
                    <Input
                      className={`${isMobile ? "" : "w-96"} ${
                        disableState ? "cursor-not-allowed" : ""
                      } text-xs1`}
                      type="text"
                      disabled={disableState}
                      placeholder="Enter Name"
                      {...formik.getFieldProps("employeeName")}
                    />
                    {formik.touched.employeeName &&
                      formik.errors.employeeName && (
                        <Error text={formik.errors.employeeName} />
                      )}
                  </Box>
                </Box>
                <Box
                  className={`${
                    isMobile
                      ? "m-6"
                      : "flex-1 m-6 flex-row md:justify-start md:flex"
                  }`}
                >
                  <Label className="px-5 py-2 text-sm font-medium text-gray-600 min-w-min w-60">
                    Email ID
                  </Label>
                  <Box>
                    <Input
                      className={`${isMobile ? "" : "w-96"} text-xs1`}
                      type="text"
                      placeholder="Email ID"
                      {...formik.getFieldProps("email")}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <Error text={formik.errors.email} />
                    )}
                  </Box>
                </Box>
                <Box
                  className={`${
                    isMobile
                      ? "m-6"
                      : "flex-1 m-6 flex-row md:justify-start md:flex"
                  }`}
                >
                  <Label className="px-5 py-2 text-sm font-medium text-gray-600 min-w-min w-60">
                    Personal Email ID
                  </Label>
                  <Box>
                    <Input
                      className={`${isMobile ? "" : "w-96"} text-xs1`}
                      type="email"
                      placeholder="Personal Email ID"
                      {...formik.getFieldProps("personalEmail")}
                    />
                    {formik.touched.personalEmail &&
                      formik.errors.personalEmail && (
                        <Error text={formik.errors.personalEmail} />
                      )}
                  </Box>
                </Box>
                <Box
                  className={`${
                    isMobile
                      ? "m-6"
                      : "flex-1 m-6 flex-row md:justify-start md:flex"
                  }`}
                >
                  <Label className="px-5 py-2 text-sm font-medium text-gray-600 min-w-min w-60">
                    Mobile
                  </Label>
                  <Box>
                    <Input
                      className={`${isMobile ? "" : "w-96"} ${
                        disableState ? "cursor-not-allowed" : ""
                      } text-xs1`}
                      type="text"
                      disabled={disableState}
                      placeholder="Contact Number"
                      {...formik.getFieldProps("contactNumber")}
                    />
                    {formik.touched.contactNumber &&
                      formik.errors.contactNumber && (
                        <Error text={formik.errors.contactNumber} />
                      )}
                  </Box>
                </Box>
                <Box
                  className={`${
                    isMobile
                      ? "m-6"
                      : "flex-1 m-6 flex-row md:justify-start md:flex"
                  }`}
                >
                  <Label className="px-5 py-2 text-sm font-medium text-gray-600 min-w-min w-60">
                    PAN
                  </Label>
                  <Input
                    className={`${isMobile ? "" : "w-96"} ${
                      disableState ? "cursor-not-allowed" : ""
                    } text-xs1 `}
                    type="text"
                    disabled={disableState}
                    placeholder="PAN Number"
                    {...formik.getFieldProps("pan")}
                  />
                </Box>
                <Box
                  className={`${
                    isMobile
                      ? "m-6"
                      : "flex-1 m-6 flex-row md:justify-start md:flex"
                  }`}
                >
                  <Label className="px-5 py-2 text-sm font-medium text-gray-600 min-w-min w-60">
                    Demat Account ID
                  </Label>
                  <Input
                    className={`${isMobile ? "" : "w-96"} ${
                      disableState ? "cursor-not-allowed" : ""
                    } text-xs1 `}
                    type="text"
                    disabled={disableState}
                    placeholder="DP ID"
                    {...formik.getFieldProps("dematAccountId")}
                  />
                </Box>
                <Box
                  className={`${
                    isMobile
                      ? "m-6"
                      : "flex-1 m-6 flex-row md:justify-start md:flex"
                  }`}
                >
                  <Label className="px-5 py-2 text-sm font-medium text-gray-600 min-w-min w-60">
                    Emp ID
                  </Label>
                  <Box>
                    <Input
                      className={`${isMobile ? "" : "w-96"} ${
                        disableState ? "cursor-not-allowed" : ""
                      } text-xs1`}
                      type="text"
                      placeholder="Employee ID"
                      disabled={disableState}
                      {...formik.getFieldProps("employeeIdentification")}
                    />
                    {formik.touched.employeeIdentification &&
                      formik.errors.employeeIdentification && (
                        <Error text={formik.errors.employeeIdentification} />
                      )}
                  </Box>
                </Box>
                <Box
                  className={`${
                    isMobile
                      ? "m-6"
                      : "flex-1 m-6 flex-row md:justify-start md:flex"
                  }`}
                >
                  <Label className="px-5 py-2 text-sm font-medium text-gray-600 min-w-min w-60">
                    Employee Type
                  </Label>
                  <Box>
                    <Select
                      className={`text-xs1 ${isMobile ? "" : "w-96"}
                      ${disableState ? "cursor-not-allowed" : ""}`}
                      options={["EMPLOYEE", "CONSULTANT"]}
                      isPlaceHolderDisabled={true}
                      disabled={disableState}
                      {...formik.getFieldProps("employmentType")}
                    />
                    {formik.touched.employmentType &&
                      formik.errors.employmentType && (
                        <Error text={formik.errors.employmentType} />
                      )}
                  </Box>
                </Box>

                <Box
                  className={`${
                    isMobile
                      ? "m-6"
                      : "flex-1 m-6 flex-row md:justify-start md:flex"
                  }`}
                >
                  <Label className="px-5 py-2 text-sm font-medium text-gray-600 min-w-min w-60">
                    Date of Joining
                  </Label>
                  <Box>
                    <Input
                      className={`${isMobile ? "" : "w-96"} ${
                        disableState ? "cursor-not-allowed" : ""
                      } text-xs1`}
                      type="date"
                      disabled={disableState}
                      placeholder="Select Date"
                      {...formik.getFieldProps("dateOfJoin")}
                    />
                    {formik.touched.dateOfJoin && formik.errors.dateOfJoin && (
                      <Error text={formik.errors.dateOfJoin} />
                    )}
                  </Box>
                </Box>
                {employeePageDetail?.status === EmployementStatus.RESIGNED && (
                  <Box
                    className={`${
                      isMobile
                        ? "m-6"
                        : "flex-1 m-6 flex-row md:justify-start md:flex"
                    }`}
                  >
                    <Label className="px-5 py-2 text-sm font-medium text-gray-600 min-w-min w-60">
                      Resignation Date
                    </Label>
                    <Box>
                      <Input
                        className={`${isMobile ? "" : "w-96"} ${
                          disableState ? "cursor-not-allowed" : ""
                        } text-xs1`}
                        type="date"
                        disabled={disableState}
                        placeholder="Enter Resignation Date"
                        {...formik.getFieldProps("resignationDate")}
                      />
                      {formik.touched.resignationDate &&
                        formik.errors.resignationDate && (
                          <Error text={formik.errors.resignationDate} />
                        )}
                    </Box>
                  </Box>
                )}
                {employeePageDetail?.status === EmployementStatus.RESIGNED && (
                  <Box
                    className={`${
                      isMobile
                        ? "m-6"
                        : "flex-1 m-6 flex-row md:justify-start md:flex"
                    }`}
                  >
                    <Label className="px-5 py-2 text-sm font-medium text-gray-600 min-w-min w-60">
                      Last Working day
                    </Label>
                    <Box>
                      <Input
                        className={` ${isMobile ? "" : "w-96"} ${
                          disableState ? "cursor-not-allowed" : ""
                        } text-xs1`}
                        type="date"
                        disabled={disableState}
                        placeholder="Select Date"
                        {...formik.getFieldProps("lastDay")}
                      />
                      {formik.touched.lastDay && formik.errors.lastDay && (
                        <Error text={formik.errors.lastDay} />
                      )}
                    </Box>
                  </Box>
                )}
                <Box
                  className={`${
                    isMobile
                      ? "m-6"
                      : "flex-1 m-6 flex-row md:justify-start md:flex"
                  }`}
                >
                  <Label className="px-5 py-2 text-sm font-medium text-gray-600 min-w-min w-60">
                    Designation
                  </Label>
                  <Box>
                    <Input
                      className={` ${isMobile ? "" : "w-96"} ${
                        disableState ? "cursor-not-allowed" : ""
                      } text-xs1`}
                      type="text"
                      disabled={disableState}
                      placeholder="Employee Designation"
                      {...formik.getFieldProps("employeeDesignation")}
                    />
                    {formik.touched.employeeDesignation &&
                      formik.errors.employeeDesignation && (
                        <Error text={formik.errors.employeeDesignation} />
                      )}
                  </Box>
                </Box>
                <Box
                  className={`${
                    isMobile
                      ? "m-6"
                      : "flex-1 m-6 flex-row md:justify-start md:flex"
                  }`}
                >
                  <Label className="px-5 py-2 text-sm font-medium text-gray-600 min-w-min w-60">
                    Department
                  </Label>
                  <Box>
                    <Input
                      className={` ${isMobile ? "" : "w-96"} ${
                        disableState ? "cursor-not-allowed" : ""
                      } text-xs1`}
                      type="text"
                      disabled={disableState}
                      placeholder="Enter Department Name"
                      {...formik.getFieldProps("department")}
                    />
                    {formik.touched.department && formik.errors.department && (
                      <Error text={formik.errors.department} />
                    )}
                  </Box>
                </Box>
                <Box
                  className={`${
                    isMobile
                      ? "m-6"
                      : "flex-1 m-6 flex-row md:justify-start md:flex"
                  }`}
                >
                  <Label className="px-5 py-2 text-sm font-medium text-gray-600 min-w-min w-60">
                    Notes
                  </Label>
                  <Box>
                    <TextArea
                      className={` ${isMobile ? "" : "w-96"} ${
                        disableState ? "cursor-not-allowed" : ""
                      } text-xs1`}
                      placeholder="Note"
                      {...formik.getFieldProps("notes")}
                    />
                    {formik.touched.notes && formik.errors.notes && (
                      <Error text={formik.errors.notes} />
                    )}
                  </Box>
                </Box>
                <Box
                  className={`${
                    isMobile
                      ? "m-6"
                      : "flex-1 m-6 flex-row md:justify-start md:flex"
                  }`}
                >
                  <Label className="px-5 py-2 text-sm font-medium text-gray-600 min-w-min w-60">
                    Comments
                  </Label>
                  <Box>
                    <TextArea
                      disabled
                      className={` ${
                        isMobile ? "" : "w-96"
                      } ${"cursor-not-allowed"} text-xs1`}
                      placeholder="Additional Data"
                      value={employeePageDetail?.additionalData?.transferAccessData?.join(
                        "\n\n"
                      )}
                    />
                  </Box>
                </Box>
                <Box className="w-full border-b"></Box>
                <Box
                  className={`${
                    isMobile ? "justify-center" : "justify-end"
                  } flex flex-row `}
                >
                  <ButtonSecondary
                    className={
                      !determineUserAccessToResource(
                        permission?.aclList || [],
                        Resource.Employee,
                        DefaultAction.Edit
                      )
                        ? "opacity-50 cursor-not-allowed m-2"
                        : "m-2"
                    }
                    disabled={
                      !determineUserAccessToResource(
                        permission?.aclList || [],
                        Resource.Employee,
                        DefaultAction.Edit
                      )
                    }
                    onClick={() => formik.resetForm()}
                  >
                    Discard
                  </ButtonSecondary>
                  <ButtonPrimary
                    type="submit"
                    className={
                      !determineUserAccessToResource(
                        permission?.aclList || [],
                        Resource.Employee,
                        DefaultAction.Edit
                      )
                        ? "opacity-50 cursor-not-allowed m-2"
                        : "m-2"
                    }
                    disabled={
                      !determineUserAccessToResource(
                        permission?.aclList || [],
                        Resource.Employee,
                        DefaultAction.Edit
                      )
                    }
                    loading={isLoading}
                  >
                    Save changes
                  </ButtonPrimary>
                </Box>
              </Form>
            </>
          )}
        </Formik>
      </Box>
    </>
  );
}
export default Profile;
