/* eslint-disable func-names */
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import DeleteIcon from "@mui/icons-material/Delete";
import { Icon } from "@iconify/react";
import _ from "lodash";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { Dialog } from "@mui/material";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  formatToTitleCase,
  Error,
  HStack,
  VStack,
  getGrantStrikePrice,
} from "../../components/utils";
import {
  GrantedBy,
  useGrantDetailsStore,
} from "../../store/useGrantDetailsStore";
import { formatDisplayDate } from "../../utils/date";
import FileInput from "../../components/shared/FileInput";
import { Label, TextArea } from "../../components/shared/InputField";
import convertToBase64 from "../../utils/convertToBase64";
import { SwitchButton } from "../../components/shared/SwitchButton";
import { useAddGrant, useEditGrant, useGrants } from "../../queries/esopPlan";
import { AddGrantReq, VestingDateType } from "../../types/Grant";
import LazyPdfDocument from "../../components/shared/LazyPdfDocument";
import { useGetAllMilestones } from "../../queries/milestone";
import { VestingMilestone } from "../../types/milestone";
import {
  formatCurrency,
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import { StrikePriceType } from "../../types/EsopPlan";

interface GrantSummaryPageProps {
  onStepChange: (e: number) => void;
  onBackClick: () => void;
}

const GrantSummaryPage = (props: GrantSummaryPageProps) => {
  const currencyType = getCurrencyType();
  const currencySymbol = getCurrencySymbol();
  const data = useGrantDetailsStore();
  const navigate = useNavigate();
  const { mode, id } = useParams();
  const { mutate: createGrant, isLoading } = useAddGrant();
  const { mutate: editGrant, isLoading: editLoading } = useEditGrant();
  const { data: grants } = useGrants();
  const [errors, setErrors] = useState<any>({});
  const { data: _allMilestones, isFetched } = useGetAllMilestones();
  const [allMilestones, setAllMilestones] = useState<VestingMilestone[]>([]);
  useEffect(() => {
    setAllMilestones(_allMilestones || []);
  }, [isFetched]);
  const [expanded, setExpanded] = useState<{
    newGrant: boolean;
    grantInformation: boolean;
    vestingSchedule: boolean;
  }>({ newGrant: true, grantInformation: false, vestingSchedule: false });
  const [documentDialog, setDocumentDialog] = useState<{
    open: boolean;
    grantLetter?: string;
  }>({ open: false, grantLetter: undefined });
  async function handleFileUpload(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e?.target?.files?.[0]) return;
    const fileData = e.target.files[0];
    if (fileData.type !== "application/pdf") return;
    const base64 = await convertToBase64(fileData);
    data.setCustomizedGrantLetter({
      file: base64 as unknown as string,
      fileName: fileData.name,
      fileType: "pdf",
      customizedLetter: true,
    });
  }
  function handleDeleteLetter() {
    data.setCustomizedGrantLetter({
      file: "",
      fileName: "",
      fileType: "",
      customizedLetter: false,
    });
  }
  const checkIfGrantIdExists = (value: string, mode = "add", id = "") => {
    if (grants) {
      const filteredGrants =
        mode === "edit"
          ? grants.filter((g) => g.optionHolderId !== id)
          : grants;
      return filteredGrants.some((grant) => grant.grantIdentifier === value);
    } else return false;
  };
  const validationSchema = Yup.object().shape({
    employee: Yup.object().required("Name of Holder is required"),
    plan: Yup.object().required("Plan Name is required"),
    dateOfGrant: Yup.date()
      .required("Grant Date is required")
      .when("employee", (employee, schema) =>
        schema.test(
          "is-after-joining-and-plan-start",
          `Grant Date must be after the employee's joining date and the plan start date.`,
          // eslint-disable-next-line func-names
          function (value) {
            const { plan } = this.parent as {
              plan?: { planStartDate?: Date; startDate?: Date };
            };
            const employeeJoiningDate = employee?.dateOfJoin;

            const startDate = plan?.planStartDate || plan?.startDate;

            if (!value || !employeeJoiningDate || !startDate) return true;

            const grantDate = new Date(value).setHours(0, 0, 0, 0);
            const joiningDate = new Date(employeeJoiningDate).setHours(
              0,
              0,
              0,
              0
            );
            const planStartDate = new Date(startDate).setHours(0, 0, 0, 0);

            return grantDate >= joiningDate && grantDate >= planStartDate;
          } as (this: Yup.TestContext, value: Date) => boolean
        )
      ),
    grantPrice: Yup.number()

      .required("Grant Price is required")
      .min(0, "Grant Price cannot be lesser than zero"),
    grantId: Yup.string().test(
      "unique-grant-id",
      "This Grant ID already exists.",
      (value) => {
        if (!value) return true;
        const exists = checkIfGrantIdExists(value, mode, id);
        return !exists;
      }
    ),
    grantedBy: Yup.string().required("Select Granted By"),
    noOfOptions: Yup.number()
      .required("No of Options is required")
      .min(
        data?.plan?.isFractional ? 0.0001 : 1,
        `No of Options must be greater than ${
          data?.plan?.isFractional ? 0.0001 : 1
        }`
      )
      .max(
        data?.plan?.optionsReserved,
        "No. of Options must be lesser than the Options Available"
      ),
    modellingId: Yup.string().when("grantedBy", {
      is: "MODEL",
      then: Yup.string().required("Model is Required"),
      otherwise: Yup.string().notRequired(),
    }),

    selectedExistingTemplate: Yup.boolean().required(
      "No of Options is required"
    ),
    selectedVestingTemplate: Yup.string().when("selectedExistingTemplate", {
      is: true,
      then: Yup.string().required(
        "Vesting Template is required when selecting an existing template"
      ),
    }),
    vestingScheduleData: Yup.array()
      .when("selectedExistingTemplate", {
        is: false,
        then: Yup.array()
          .of(
            Yup.object().shape({
              vestingDate: Yup.string().required("Vesting Date is required"),
              percentage: Yup.number().required("Percentage is required"),
              vestingOptions: Yup.number().required(
                "Vesting Options are required"
              ),
              milestoneId: Yup.string().optional(),
            })
          )
          .required("Vesting Schedule Data is required")
          .test(
            "options-match",
            "Sum of Vesting Options must match Options Granted",
            function (vestingScheduleData) {
              const { optionsGranted, isFractional } = this.parent || {};

              if (!vestingScheduleData || !optionsGranted) return true;
              const totalVestingOptions = vestingScheduleData.reduce(
                (sum, item) => sum + (item.vestingOptions || 0),
                0
              );

              return isFractional
                ? parseFloat(totalVestingOptions.toFixed(4)) === optionsGranted
                : parseInt(totalVestingOptions.toString(), 10) ===
                    optionsGranted;
            }
          ),
      })
      .test(
        "vesting-date-check",
        "Vesting Date cannot be earlier than the Grant Date",
        function (vestingScheduleData) {
          const { dateOfGrant } = this.parent || {};

          if (!vestingScheduleData || !dateOfGrant) return true;

          const isValid = vestingScheduleData.every((item) => {
            const vestingDate = new Date(item?.vestingDate || new Date());
            const grantDateObj = new Date(dateOfGrant);

            return vestingDate >= grantDateObj;
          });

          return isValid;
        }
      ),
  });

  const formik = useFormik({
    initialValues: {},
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: () => {},
  });

  const validateData = async () => {
    const errors = await formik.validateForm({
      selectedExistingTemplate: data.selectedExistingTemplate,
      selectedVestingTemplate: data?.vestingTemplate?.id || "",
      optionsGranted: data?.optionsGranted,
      vestingScheduleData: data?.vestingScheduleData,
      noOfOptions: data.optionsGranted || 0,
      modellingId: data.modellingId,
      employee: data.employee,
      grantId: data.grantId,
      plan: data.plan,
      dateOfGrant: data.dateOfGrant,
      grantPrice: data.grantPrice || 0,
      grantedBy: data.grantedBy || GrantedBy.OPTIONS,
      isFractional: data?.plan?.isFractional || false,
    });
    return errors;
  };

  function handleSubmit() {
    validateData().then((errors) => {
      if (_.isEmpty(errors)) {
        if (data.employee?.id && data.plan?.esopPlanId) {
          const addReqData: AddGrantReq = {
            employeeId: data.employee.id,
            employeeName: data.employee.employeeName,
            planId: data.plan.esopPlanId,
            planName: data.plan.planName,
            planType: data.plan.planType,
            optionsGranted: data.optionsGranted,
            grantPrice: data.grantPrice || 0,
            grantType:
              data.grantType && data.grantType !== ""
                ? data.grantType
                : undefined,
            dateOfGrant: data.dateOfGrant,
            vestingDateType: data.selectedExistingTemplate
              ? data.vestingDateType
              : VestingDateType.GRANT_DATE,
            vestingTemplate: data.selectedExistingTemplate
              ? data.vestingTemplate.vestingTemplateName
              : "",
            vestingId: data.selectedExistingTemplate
              ? data.vestingTemplate.id
              : "",
            vestingDate: data.selectedExistingTemplate ? data.vestingDate : "",
            customizedGrantLetter:
              data.customizedGrantLetter.customizedLetter &&
              (data.customizedGrantLetter.file !== "" ||
                data.customizedGrantLetter.link !== ""),
            grantLetterType:
              data.customizedGrantLetter &&
              data.customizedGrantLetter.fileType !== ""
                ? data.customizedGrantLetter.fileType
                : "",
            grantLetter:
              data.customizedGrantLetter &&
              data.customizedGrantLetter.file !== ""
                ? data.customizedGrantLetter.file
                : "",
            grantLetterFileName:
              data.customizedGrantLetter &&
              data.customizedGrantLetter.fileName !== ""
                ? data.customizedGrantLetter.fileName
                : "",
            actualVestingDay: data.selectedExistingTemplate
              ? data.actualVestingDay
              : "SAME DAY",
            grantId: data.grantId || "",
            grantedBY: data.grantedBy || GrantedBy.OPTIONS,
            manuallyCreatedTemplate: !data.selectedExistingTemplate,
            templateName: data.selectedExistingTemplate
              ? ""
              : data.manualTemplateName,
            vestingScheduleData: data.selectedExistingTemplate
              ? []
              : data.vestingScheduleData,
            sharePrice: data.sharePrice,
            valuationId: data.valuationId,
            modellingId: data.modellingId,
            id,
            note: data.note,
          };
          if (mode === "add") {
            createGrant(addReqData, {
              onSuccess: () => {
                toast("Grant Created successfully!", {
                  type: "success",
                  autoClose: 2000,
                });
                data.clear();
                navigate("/options/allPlans");
              },
              onError: (error: any) => {
                const errorMessage = error.response.data.errorRaw as string;
                if (errorMessage.includes("Template Error")) {
                  toast(errorMessage, {
                    type: "warning",
                    autoClose: 5000,
                  });
                  navigate("/options/allPlans");
                } else {
                  toast(errorMessage, {
                    type: "error",
                    autoClose: 5000,
                  });
                }
              },
            });
          } else if (mode === "edit") {
            editGrant(addReqData, {
              onSuccess: () => {
                toast("Grant Edited successfully!", {
                  type: "success",
                  autoClose: 5000,
                });
                data.clear();
                navigate("/options/allPlans");
              },
              onError: (error: any) => {
                toast(error.response.data.errorRaw, {
                  type: "error",
                  autoClose: 5000,
                });
                navigate("/options/allPlans");
              },
            });
          }
        }
      } else {
        setErrors(errors);
        setExpanded({
          grantInformation: true,
          newGrant: true,
          vestingSchedule: true,
        });
      }
    });
  }

  return (
    <VStack className="w-full gap-5 px-4">
      <VStack className="justify-between gap-1 pb-3 pl-2 font-medium border-b-[0.5px] pt-7">
        <HStack className="flex justify-between font-semibold text-lg1 text-black-501 ">
          Grant Summary
        </HStack>
        <HStack className="flex font-medium text-sm3 text-gray-401">
          Review the grant details and vesting schedule, and finalize the ESOP
          grant.
        </HStack>
      </VStack>
      <VStack className="flex-1 border rounded-lg shadow-box">
        <HStack
          className="items-center justify-between p-4 cursor-pointer"
          onClick={() =>
            setExpanded((prev) => ({ ...prev, newGrant: !prev.newGrant }))
          }
        >
          <VStack>
            <h5 className="text-sm font-medium text-black-501">New Grant</h5>
            {!expanded.newGrant && (
              <HStack className="gap-2 pt-1 text-gray-400 text-xxs">
                <span>
                  Employee Name: {data?.employee?.employeeName || ""} |{""}
                </span>
                <span>Plan Name: {data?.plan?.planName || ""}</span>
              </HStack>
            )}
          </VStack>
          <HStack className="items-center gap-8">
            {expanded.newGrant && (
              <ButtonPrimary1
                onClick={(e) => {
                  e.stopPropagation();
                  props.onStepChange(1);
                }}
              >
                Edit
              </ButtonPrimary1>
            )}
            <Box>
              {expanded.newGrant ? (
                <Icon icon="fe:arrow-up" width={16} />
              ) : (
                <Icon icon="fe:arrow-up" width={16} className="rotate-180" />
              )}
            </Box>
          </HStack>
        </HStack>
        {expanded.newGrant && (
          <HStack className="flex-wrap grow">
            {[
              {
                field: "Grant By",
                value: formatToTitleCase(data?.grantedBy || "OPTIONS"),
                error: errors.grantedBy,
              },
              {
                field: "Name of Holder",
                value: data.employee?.employeeName || data.employee?.name || "",
                error: errors.employeeName,
              },
              {
                field: "Grant Id",
                value: !data.grantId
                  ? "It will get Auto-Generated"
                  : data.grantId,
                error: errors.grantId,
              },
              {
                field: "Plan Name",
                value: data.plan?.planName || "",
                error: errors.planName,
              },
              {
                field: "Grant Date",
                value: data.dateOfGrant
                  ? formatDisplayDate(data.dateOfGrant)
                  : "",
                error: errors.dateOfGrant,
              },
              {
                field: "Grant Type",
                value: !data.grantType ? "ESOP" : data.grantType,
              },
            ].map(({ field, value, error }, i) => (
              <HStack key={i} className="w-1/2 gap-1 p-4">
                <span className="w-1/3 text-sm font-normal text-gray-400">
                  {field}
                </span>
                <VStack className="w-2/3">
                  <span className="text-sm font-normal ">
                    {value?.toString()}
                  </span>
                  {error && <Error text={error} />}
                </VStack>
              </HStack>
            ))}
          </HStack>
        )}
      </VStack>
      <VStack className="flex-1 border rounded-lg shadow-box">
        <HStack
          className="items-center justify-between p-4 cursor-pointer"
          onClick={() =>
            setExpanded((prev) => ({
              ...prev,
              grantInformation: !prev.grantInformation,
            }))
          }
        >
          <VStack>
            <h5 className="text-sm font-medium text-black-501">
              Grant Information
            </h5>
            {!expanded.grantInformation && (
              <HStack className="gap-2 pt-1 text-gray-400 text-xxs">
                <span>No of Options: {data?.optionsGranted || ""}</span>|{" "}
                {!expanded.grantInformation && (
                  <span>
                    Cost of Grant:{" "}
                    {formatCurrency(
                      data.optionsGranted *
                        getGrantStrikePrice(
                          data?.grantPrice || 0,
                          data.plan?.conversionShares || 1,
                          data.plan?.strikePriceType ||
                            StrikePriceType.OPTIONS_PRICE
                        ) || 0,
                      currencyType
                    )}
                  </span>
                )}
                |{" "}
                {!expanded.grantInformation && (
                  <span>
                    Value of Shares:{" "}
                    {formatCurrency(
                      data.optionsGranted *
                        data.sharePrice *
                        (data.plan?.conversionShares || 1),
                      currencyType
                    ) || ""}
                  </span>
                )}
              </HStack>
            )}
          </VStack>
          <HStack className="items-center gap-8">
            {expanded.grantInformation && (
              <ButtonPrimary1
                onClick={(e) => {
                  e.stopPropagation();
                  props.onStepChange(2);
                }}
              >
                Edit
              </ButtonPrimary1>
            )}
            <Box>
              {expanded.grantInformation ? (
                <Icon icon="fe:arrow-up" width={16} />
              ) : (
                <Icon icon="fe:arrow-up" width={16} className="rotate-180" />
              )}
            </Box>
          </HStack>
        </HStack>
        {expanded.grantInformation && (
          <HStack className="flex-wrap grow">
            {[
              {
                field: "No of Options",
                value: data.optionsGranted.toLocaleString(currencyType, {
                  maximumFractionDigits: 4,
                }),
                error: errors.noOfOptions,
              },
              {
                field: "Share Price",
                value: formatCurrency(data.sharePrice, currencyType),
              },
              {
                field: "Value Of Shares",
                value:
                  data.grantedBy === GrantedBy.AMOUNT
                    ? formatCurrency(
                        data.optionsGranted *
                          data.sharePrice *
                          (data.plan?.conversionShares || 1),
                        currencyType
                      )
                    : formatCurrency(data.valueOfOptions, currencyType),
              },
              {
                field: "Cost of Grant",
                value: formatCurrency(
                  data.optionsGranted *
                    getGrantStrikePrice(
                      data?.grantPrice || 0,
                      data.plan?.conversionShares || 1,
                      data.plan?.strikePriceType ||
                        StrikePriceType.OPTIONS_PRICE
                    ),
                  currencyType
                ),
              },
              {
                field: "Grant Price",
                value: formatCurrency(data.grantPrice, currencyType),
                error: errors.grantPrice,
              },
            ].map(({ field, value, error }, i) => (
              <HStack key={i} className="w-1/2 gap-1 p-4">
                <span className="w-1/3 text-sm font-normal text-gray-400">
                  {field}
                </span>
                <VStack className="w-2/3">
                  <span className="text-sm font-normal ">
                    {value?.toString()}
                  </span>
                  {error && <Error text={error} />}
                </VStack>
              </HStack>
            ))}
          </HStack>
        )}
      </VStack>
      <VStack className="flex-1 border rounded-lg shadow-box">
        <HStack
          className="items-center justify-between p-4 cursor-pointer"
          onClick={() =>
            setExpanded((prev) => ({
              ...prev,
              vestingSchedule: !prev.vestingSchedule,
            }))
          }
        >
          <VStack>
            <h5 className="text-sm font-medium text-black-501">
              Vesting Schedule
            </h5>
            {!expanded.vestingSchedule && (
              <HStack className="gap-2 pt-1 text-gray-400 text-xxs">
                {data.selectedExistingTemplate ? (
                  <span>
                    Template Name:{" "}
                    {data?.vestingTemplate?.vestingTemplateName || ""}
                  </span>
                ) : (
                  <span>
                    Template Name: {data?.manualTemplateName || "Manual"}
                  </span>
                )}
              </HStack>
            )}
          </VStack>
          <HStack className="items-center gap-8">
            {expanded.vestingSchedule && (
              <ButtonPrimary1
                onClick={(e) => {
                  e.stopPropagation();
                  props.onStepChange(3);
                }}
              >
                Edit
              </ButtonPrimary1>
            )}
            <Box>
              {expanded.vestingSchedule ? (
                <Icon icon="fe:arrow-up" width={16} />
              ) : (
                <Icon icon="fe:arrow-up" width={16} className="rotate-180" />
              )}
            </Box>
          </HStack>
        </HStack>
        <Dialog
          open={documentDialog.open}
          onClose={() => setDocumentDialog({ open: false })}
          maxWidth="lg"
        >
          <LazyPdfDocument url={documentDialog.grantLetter ?? ""} />
        </Dialog>
        {expanded.vestingSchedule && (
          <>
            <HStack className="flex-wrap grow">
              {[
                ...(data.selectedExistingTemplate
                  ? [
                      {
                        field: "Vesting Schedule",
                        value: data?.vestingTemplate?.vestingTemplateName || "",
                        error: errors.selectedVestingTemplate,
                      },
                      {
                        field: "Vesting Date Type",
                        value: formatToTitleCase(
                          (
                            data?.vestingDateType || VestingDateType.GRANT_DATE
                          ).replace("_", " ")
                        ),
                      },
                      {
                        field: "Actual Vesting Day",
                        value: formatToTitleCase(
                          (data?.actualVestingDay || "SAME_DAY").replace(
                            "_",
                            " "
                          )
                        ),
                      },
                      ...(data.vestingDateType &&
                      data.vestingDateType === VestingDateType.CUSTOM_DATE
                        ? [
                            {
                              field: "Vesting Start Date",
                              value: data?.vestingDate
                                ? formatDisplayDate(data?.vestingDate)
                                : "",
                            },
                          ]
                        : []),
                    ]
                  : [
                      {
                        field: "Template Name",
                        value: data?.manualTemplateName || "",
                      },
                    ]),
              ].map(({ field, value, error }, i) => (
                <HStack key={i} className="w-1/2 gap-1 p-4">
                  <span className="w-1/3 text-sm font-normal text-gray-400">
                    {field}
                  </span>
                  <VStack className="w-2/3">
                    <span className="text-sm font-normal">{value}</span>
                    {error && <Error text={error} />}
                  </VStack>
                </HStack>
              ))}
            </HStack>
            {!data.selectedExistingTemplate && (
              <HStack className="pl-3">
                <table className="w-full table-space">
                  <thead className="text-xxs font-normal text-[#B8B7C9]">
                    <tr className="border-b border-gray-200 border-dashed">
                      <td className="px-2 py-2 align-top">Vesting Date</td>
                      <td className="px-2 py-2 align-top">No. Of options</td>
                      {data.vestingScheduleData &&
                        data.vestingScheduleData.some((v) => v.milestoneId) && (
                          <td className="px-2 py-2 align-top">
                            Milestone Name
                          </td>
                        )}
                    </tr>
                  </thead>
                  <tbody className="text-[#4E4E4E] ">
                    {data.vestingScheduleData.map((vesting, index) => (
                      <tr
                        key={index}
                        className="border-b border-gray-200 border-dashed "
                      >
                        <td className="px-2 py-2 align-top ">
                          <HStack>
                            <div className="mr-2 whitespace-nowrap">
                              {formatDisplayDate(vesting.vestingDate)}
                            </div>
                          </HStack>
                        </td>
                        <td className="px-2 py-2 align-top ">
                          <HStack>
                            <div className="mr-2 whitespace-nowrap">
                              {vesting.vestingOptions.toLocaleString(
                                currencyType,
                                {
                                  maximumFractionDigits: 4,
                                }
                              )}
                            </div>
                          </HStack>
                        </td>
                        {data.vestingScheduleData &&
                          data.vestingScheduleData.some(
                            (v) => v.milestoneId
                          ) && (
                            <td className="px-2 py-2 align-top ">
                              <HStack>
                                <div className="mr-2 whitespace-nowrap">
                                  {allMilestones.find(
                                    (m) => m.id === vesting.milestoneId
                                  )?.milestoneName || ""}
                                </div>
                              </HStack>
                            </td>
                          )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </HStack>
            )}
          </>
        )}
      </VStack>
      <HStack>
        <div className="flex-1 pt-4 pl-2">
          <Label className="pb-1 text-sm font-medium text-black-501">
            Upload Custom Grant Letter
          </Label>
          <div className="flex flex-row items-center pl-2 cursor-pointer align-center">
            {data.customizedGrantLetter.customizedLetter && mode !== "add" ? (
              <>
                <div className="flex flex-row items-center justify-between cursor-pointer align-center">
                  {data.customizedGrantLetter.link ? (
                    <div
                      className="text-red-500 underline "
                      onClick={() =>
                        setDocumentDialog({
                          open: true,
                          grantLetter: data.customizedGrantLetter.link,
                        })
                      }
                    >
                      Grant Letter.pdf
                    </div>
                  ) : (
                    <FileInput
                      accept="application/pdf"
                      file={data.customizedGrantLetter.fileName || "Replace"}
                      onChange={(e) => {
                        handleFileUpload(e);
                      }}
                    />
                  )}
                </div>
              </>
            ) : (
              <FileInput
                accept="application/pdf"
                file={data.customizedGrantLetter?.fileName || "Attach File"}
                onChange={(e) => {
                  handleFileUpload(e);
                }}
              />
            )}
            <button
              onClick={() => handleDeleteLetter()}
              className="ml-6 text-zinc-300 hover:scale-105"
            >
              <DeleteIcon />
            </button>
          </div>
        </div>
        <div className="flex-1"></div>
      </HStack>
      <HStack>
        <div className="flex-1 pt-4 pl-2">
          <Label className="text-sm font-normal">Notes</Label>
          <TextArea
            value={data?.note || ""}
            onChange={(e) => data.setNote(e.target.value)}
          />
        </div>
      </HStack>
      <HStack className="justify-between pt-4">
        <ButtonPrimary1 onClick={props.onBackClick}>Back</ButtonPrimary1>
        <ButtonPrimary
          onClick={() => handleSubmit()}
          loading={isLoading || editLoading}
        >
          Save Grant
        </ButtonPrimary>
      </HStack>
    </VStack>
  );
};

export default GrantSummaryPage;
