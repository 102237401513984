import { Icon } from "@iconify/react";
import { CircularProgress } from "@mui/material";
import { ChartData } from "chart.js";
import { format } from "date-fns";
import { Form, Formik, useFormikContext } from "formik";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useIsMutating } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import * as Yup from "yup";
import AlertDialog from "../../components/shared/AlertDialog";
import { Input, Label, TextArea } from "../../components/shared/InputField";
import Pagination from "../../components/shared/Pagination";
import { Select } from "../../components/shared/Select";
import { SelectButtonGroup } from "../../components/shared/SelectButtonGroup";
import { SwitchButton } from "../../components/shared/SwitchButton";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  ButtonSecondary,
  Center,
  Error,
  HStack,
  VStack,
} from "../../components/utils";
import ForfeitureTypes from "../../constants/ForfeitureTypes";
import PlanTypes from "../../constants/PlanTypes";
import {
  useAddVestingTemplateForPlan,
  useVestingTemplates,
} from "../../queries";
import {
  useAddPlan,
  useCreatePlanDetails,
  useEditPlan,
  useEsopPlans,
  useGetAllPlanDocs,
  useGetEsopPlanDetails,
  usePlanStateChange,
  usePlanTemplates,
  useTrustDetails,
} from "../../queries/esopPlan";
import { useCompanyStore } from "../../store";
import { useError } from "../../store/errorStore";
import {
  AddPlanReq,
  AddPlanRes,
  ForfeitureCondition,
  ForfeitureType,
  PlanState,
  StrikePriceType,
} from "../../types/EsopPlan";
import { TrustDetails } from "../../types/Grant";
import {
  TriggerType,
  VestingTemplate,
  VestingType,
} from "../../types/VestingTemplate";
import { getCurrencyType } from "../../utils/currencyFormatter";
import {
  formatDate,
  formatDisplayDate,
  formatWithTimeZone,
} from "../../utils/date";
import { getFormattedValue } from "../../utils/string";
import { AddOrEditVestingTemplate } from "../vestingSchedules/AddVestingTemplate";
import { BarChart } from "../vestingSchedules/BarChart";
import { generateProjections } from "../vestingSchedules/generateProjections";
import { AddOrEditTrust } from "./AddOrEditTrust";
import PlanDocument from "./PlanDocument";
import { planInitValues } from "./planInitValues";

export default function AddOrEditPlan() {
  const { mode, id } = useParams();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const tab = queryParams.get("tab");
  const { mutate: addPlan } = useAddPlan();
  const { mutate: editPlan } = useEditPlan();
  const { mutate: createVestingScheduleWithTemplate } =
    useAddVestingTemplateForPlan();
  const [activeStepNo, setActiveStepNo] = useState(1);
  const navigate = useNavigate();
  const { data: _vestingTemplates } = useVestingTemplates();
  const vestingTemplates = _vestingTemplates || [];
  const { data: _trustDetails } = useTrustDetails();
  const trustDetails = _trustDetails || [];
  const { data: createPlanDetails } = useCreatePlanDetails();
  const { companyData } = useCompanyStore();
  const { data } = useGetEsopPlanDetails(id ?? "", mode !== "add" && !!id);
  const { data: _plans } = useEsopPlans();
  const plans = _plans || [];
  const [responseData, setResponseData] = useState<AddPlanRes>();
  useEffect(() => {
    if (!mode) {
      toast("No mode available", {
        type: "error",
        autoClose: 2000,
      });
      navigate("/options/esopOverview");
    }
    if (mode && mode !== "add" && !id) {
      toast("Plan not found", {
        type: "error",
        autoClose: 2000,
      });
      navigate("/options/esopOverview");
    }
    if (mode && mode !== "add" && id) {
      updateActiveStepNo(2);
    }
  }, []);

  useEffect(() => {
    if (tab === "Summary") {
      updateActiveStepNo(5);
      steps.forEach((step) => {
        if (step.no < 5) {
          step.isCompleted = true;
        } else {
          step.isCompleted = false;
        }
      });
      setStepsToBePassed(steps);
    }
  }, []);

  function getInitialValues() {
    if (mode !== "add" && data) {
      const existingPlanValues: AddPlanReq = {
        planName: data.planName,
        planType: data.planType,
        planStartDate: data.planStartDate && formatDate(data.planStartDate),
        poolSize: data.poolSize,
        planTerm: data.planTerm,
        planDescription: data.planDescription,
        notes: data.note ?? "",
        vestingTemplateId: data.vesting.id,
        conversionNumber: data.conversionNumber || 1,
        conversionShares: data.conversionShares || 1,
        poolShares: data.poolSize / data.conversionNumber || 0,
        vestingTemplateName: data.vesting.vestingTemplateName,
        isFractional: data.isFractional,
        isManagedByTrust: data.isManagedByTrust,
        trustId: data.trustId,
        trustName: data.trustName,
        forfeitureType: data.forfeitureType,
        forfeitureConditions: data.forfeitureConditions,
        sharesManagedByTrust: data.sharesManagedByTrust || 0,
        unitName: data.unitName,
        unitType: data.unitType,
        id: data.id,
        strikePriceType: data.strikePriceType || StrikePriceType.OPTIONS_PRICE,
      };
      return existingPlanValues;
    } else if (mode === "add" && responseData) {
      const existingPlanValues: AddPlanReq = {
        planName: responseData.planName,
        planType: responseData.planType,
        planStartDate:
          responseData.planStartDate && formatDate(responseData.planStartDate),
        poolSize: responseData.poolSize,
        planTerm: responseData.planTerm,
        planDescription: responseData.planDescription,
        notes: responseData.note ?? "",
        vestingTemplateId: responseData.vesting.id,
        conversionNumber: responseData.conversionNumber || 1,
        conversionShares: responseData.conversionShares || 1,
        poolShares: responseData.poolSize / responseData.conversionNumber || 0,
        vestingTemplateName: responseData.vesting.vestingTemplateName,
        isFractional: responseData.isFractional,
        isManagedByTrust: responseData.isManagedByTrust,
        trustId: responseData.trustId,
        trustName: responseData.trustName,
        forfeitureType: responseData.forfeitureType,
        forfeitureConditions: responseData.forfeitureConditions,
        sharesManagedByTrust: responseData.sharesManagedByTrust || 0,
        unitName: responseData.unitName,
        unitType: responseData.unitType,
        id: responseData.id,
        strikePriceType:
          responseData.strikePriceType || StrikePriceType.OPTIONS_PRICE,
      };
      return existingPlanValues;
    } else {
      return planInitValues;
    }
  }
  const steps = [
    { no: 1, title: "Choose a Template", isCompleted: false },
    { no: 2, title: "Plan Details", isCompleted: false },
    { no: 3, title: "Vesting Schedule", isCompleted: false },
    { no: 4, title: "Options Expiry", isCompleted: false },
    { no: 5, title: "Summary", isCompleted: false },
  ];

  const [stepsToBePassed, setStepsToBePassed] = useState(steps);
  const [stepsValid, setStepValid] = useState({
    template: false,
    basicDetails: false,
    vestingSchedules: false,
    excerciseWindow: false,
  });
  const minStartDate = new Date(
    companyData?.companyMetaData.dateOfIncorporation || "1900-01-01"
  );
  const validationSchema = Yup.object({
    planType: Yup.string()
      .required("Required")
      .oneOf(PlanTypes.map((t) => t.value)),
    forfeitureType: Yup.string()
      .required("Required")
      .oneOf(ForfeitureTypes.map((t) => t.value)),
    forfeitureCondition: Yup.array(),
    planName: Yup.string()
      .required("Plan Name is required")
      .trim()
      .min(3, "Minimum three characters required")
      .max(100)
      .matches(/^[a-zA-Z0-9\s\-(),"”“–]+$/, "No special characters allowed")
      .test(
        "plan Already Exists",
        "Plan Name Already Exists in the company",
        (value) => {
          if (
            mode === "add" &&
            value &&
            plans.some((t) => t.planName.toLowerCase() === value.toLowerCase())
          ) {
            return false;
          } else {
            return true;
          }
        }
      ),
    planStartDate: Yup.date().min(
      minStartDate,
      `Plan date should not be before company Incorporation date (
    ${format(
      new Date(
        companyData?.companyMetaData.dateOfIncorporation || "1900-01-01"
      ),
      "dd-MM-yyyy"
    )})`
    ),
    poolSize: Yup.number()
      .test(
        "Pool Size",
        "Pool Size must be lesser than available options ",
        (_value, context) => {
          const value = context.parent as AddPlanReq;
          const optionsAvailable =
            data?.esopPlanState === PlanState.ACTIVE ||
            data?.esopPlanState === PlanState.AMENDMENT
              ? (createPlanDetails?.esopPoolCount || 0) +
                (data?.poolSize || 0) * (data?.conversionNumber || 1)
              : createPlanDetails?.esopPoolCount || 0;
          const sharesAvailable = optionsAvailable * value.conversionNumber;
          if (value.poolSize > sharesAvailable) {
            return false;
          }
          return true;
        }
      )
      .test(
        "is-fractional",
        "Pool Size must be an integer",
        (_value, context) => {
          const value = context.parent as AddPlanReq;
          if (value?.isFractional) {
            return true;
          } else {
            return Number.isInteger(value?.poolSize);
          }
        }
      ),
    planTerm: Yup.number()
      .required("Plan Term  is required")
      .positive("Plan Term must be a positive number"),
    planDescription: Yup.string()
      .required("required")
      .min(3, "minimum three characters required")
      .max(500),
    notes: Yup.string(),
    vestingTemplateName: Yup.string().required("required"),
    conversionNumber: Yup.number()
      .integer("conversion number should be an integar")
      .positive("should not be zero"),
    poolShares: Yup.number().integer("Pool Share should be an integar"),
    isManagedByTrust: Yup.boolean(),
    sharesManagedByTrust: Yup.number().when("isManagedByTrust", {
      is: true,
      then: Yup.number()
        .required("Enter shares managed by trust")
        .integer("Shares cannot be in decimal")
        .moreThan(0, "Enter shares managed by trust")
        .test(
          "trust shares",
          "Shares Managed Cannot be more than pool shares",
          (_value, context) => {
            const value = context.parent as AddPlanReq;
            return value.sharesManagedByTrust
              ? value.poolShares >= value.sharesManagedByTrust
              : true;
          }
        )
        .test(
          "trust available shares",
          "Shares Managed Cannot be more than trust available shares",
          (_value, context) => {
            const value = context.parent as AddPlanReq;
            const trust = trustDetails.find((t) => t.id === value.trustId);
            const alreadyUsedInPlan =
              trust?.id === data?.trustId ? data?.sharesManagedByTrust : 0;
            if (
              trust &&
              _value &&
              trust.availableShares + (alreadyUsedInPlan || 0) < _value
            ) {
              return false;
            } else {
              return true;
            }
          }
        ),
    }),
  });
  const errorMessage = useError();
  function getIfPreviousStepIsValid(targetStepNo: number) {
    if (activeStepNo === 1) {
      return false;
    }
    if (activeStepNo === 5 && mode !== "View") {
      return false;
    }
    if (activeStepNo > 1 && targetStepNo === 1) {
      return false;
    }
    if (targetStepNo < activeStepNo) {
      return true;
    }
    switch (activeStepNo) {
      case 2:
        return stepsValid.basicDetails;
      case 3:
        return stepsValid.vestingSchedules;
      case 4:
        return stepsValid.excerciseWindow;
      default:
        return true;
    }
  }
  function updateActiveStepNo(activeStepNo: number) {
    steps.forEach((step) => {
      if (step.no < activeStepNo) {
        step.isCompleted = true;
      } else {
        step.isCompleted = false;
      }
    });
    setStepsToBePassed(steps);
    setActiveStepNo(activeStepNo);
  }
  function handleEdit(step: number) {
    const planId = responseData?.id || id;
    navigate(`/options/plan/edit/${planId}`);
    updateActiveStepNo(step);
  }
  function handleSkipToSummary(addPlanReq: AddPlanReq) {
    addPlan(addPlanReq, {
      onSuccess: (data) => {
        setResponseData(data.data);
        toast("Plan Constructed!", { type: "success" });
        updateActiveStepNo(5);
      },
      onError: (err: any) => {
        errorMessage.setMessage(err.response.data.reason);
        toast(err.response.data.reason, {
          type: "error",
          autoClose: 2000,
        });
      },
    });
  }
  const updateStepValidity = (
    stepName: "basicDetails" | "vestingSchedules" | "excerciseWindow",
    stepValue: boolean
  ) => {
    setStepValid((value) => ({ ...value, [stepName]: stepValue }));
  };
  return (
    <Formik
      enableReinitialize
      initialValues={getInitialValues()}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        const form = { ...values };
        form.vestingTemplateId =
          vestingTemplates.find(
            (v) => v.vestingTemplateName === form.vestingTemplateName
          )?.id ?? "";
        form.trustId =
          trustDetails.find((t) => t.trustName === form.trustName)?.id ?? "";
        form.planStartDate =
          form.planStartDate && formatWithTimeZone(form.planStartDate);
        if (!form.isManagedByTrust) {
          form.trustId = undefined;
          form.trustName = "";
          form.sharesManagedByTrust = 0;
        }
        if (form.planType === "RSU") {
          form.isFractional = false;
        }
        if (mode === "edit") {
          form.id = data?.id;
          editPlan(form, {
            onSuccess: (data) => {
              updateActiveStepNo(activeStepNo + 1);
              toast("Plan Amended!", { type: "success" });
              setResponseData(data.data);
            },
            onError: (err: any) => {
              errorMessage.setMessage(err.response.data.reason);
              toast(err.response.data.reason, {
                type: "error",
                autoClose: 2000,
              });
            },
          });
        } else if (mode === "view") {
          updateActiveStepNo(activeStepNo + 1);
          setResponseData(data);
        } else {
          addPlan(form, {
            onSuccess: (data) => {
              updateActiveStepNo(activeStepNo + 1);
              toast("Plan Constructed!", { type: "success" });
              setResponseData(data.data);
            },
            onError: (err: any) => {
              errorMessage.setMessage(err.response.data.reason);
              toast(err.response.data.reason, {
                type: "error",
                autoClose: 2000,
              });
            },
          });
        }
      }}
    >
      <Form>
        <HStack className="bg-white border-b border-solid rounded">
          <VStack className="w-[19%] gap-12 mt-14">
            {stepsToBePassed.map((step) => (
              <button
                type="button"
                key={step.no}
                onClick={() =>
                  // getIfPreviousStepIsValid(step.no) &&
                  updateActiveStepNo(step.no)
                }
              >
                <HStack className="items-center font-medium">
                  <Center
                    className={`w-8 h-8 rounded-[4px] shadow ml-6 mr-2 ${
                      activeStepNo === step.no ? "bg-orange-501 text-white" : ""
                    }`}
                  >
                    {step.isCompleted ? (
                      <Icon
                        icon="ic:round-check"
                        width={20}
                        className="text-orange-501"
                      />
                    ) : (
                      step.no
                    )}
                  </Center>
                  {step.title}
                </HStack>
              </button>
            ))}
          </VStack>
          <div className="w-[1px] bg-gray-light m-6"></div>
          <div className={`w-4/5 flex flex-col min-h-[600px]`}>
            {activeStepNo === 1 && (
              <Template
                onNext={() => updateActiveStepNo(activeStepNo + 1)}
                onSelectTemplate={(responseData: AddPlanRes) => {
                  const vestingDto: VestingTemplate = {
                    cliffPeriod: responseData.vesting.cliffPeriod,
                    id: "",
                    includeEventVesting: false,
                    includeTimeVesting: true,
                    isDefault: false,
                    schedules: responseData.vesting.schedules,
                    vestingTemplateName:
                      responseData.vesting.vestingTemplateName,
                    vestingType: responseData.vesting.vestingType,
                    numberOfGrantsAffected: 0,
                    isEditable: false,
                    isFractional: false,
                  };
                  createVestingScheduleWithTemplate(vestingDto, {
                    onSuccess: (data) => {
                      responseData.vesting = data.data;
                      responseData.planName = "";
                      setResponseData(responseData);
                      updateActiveStepNo(activeStepNo + 1);
                    },
                    onError: (err: any) => {
                      errorMessage.setMessage(err.response.data.reason);
                      toast(err.response.data.reason, {
                        type: "error",
                        autoClose: 2000,
                      });
                    },
                  });
                }}
              />
            )}
            {activeStepNo === 2 && (
              <BasicDetails
                setStepValidity={(value: boolean) =>
                  updateStepValidity("basicDetails", value)
                }
                onBack={() => updateActiveStepNo(activeStepNo - 1)}
                handleSkipToSummary={handleSkipToSummary}
                onNext={() => updateActiveStepNo(activeStepNo + 1)}
                handleEdit={(step) => handleEdit(step)}
                mode={mode}
                responseData={responseData || data}
              />
            )}
            {activeStepNo === 3 && (
              <VestingSchedules
                setStepValidity={(value: boolean) =>
                  updateStepValidity("vestingSchedules", value)
                }
                mode={mode}
                handleSkipToSummary={handleSkipToSummary}
                onBack={() => updateActiveStepNo(activeStepNo - 1)}
                onNext={() => updateActiveStepNo(activeStepNo + 1)}
                handleEdit={(step) => handleEdit(step)}
              />
            )}
            {activeStepNo === 4 && (
              <ExerciseWindows
                setStepValidity={(value: boolean) =>
                  updateStepValidity("excerciseWindow", value)
                }
                mode={mode}
                handleSkipToSummary={handleSkipToSummary}
                onBack={() => updateActiveStepNo(activeStepNo - 1)}
                onNext={() => updateActiveStepNo(activeStepNo + 1)}
                handleEdit={(step) => handleEdit(step)}
              />
            )}
            {activeStepNo === 5 && (
              <Summary
                setStepValidity={(_value: boolean) => mode === "view"}
                onBack={() => navigate("/options/esopOverview")}
                mode={mode}
                handleSkipToSummary={handleSkipToSummary}
                onNext={() => {
                  navigate("/options/esopOverview");
                }}
                handleEdit={(step) => handleEdit(step)}
                responseData={responseData || data}
              />
            )}
          </div>
        </HStack>
      </Form>
    </Formik>
  );
}

type StepProps = {
  onBack: () => void;
  onNext: () => void;
  setStepValidity: (value: boolean) => void;
  mode?: string | null;
  handleEdit: (step: number) => void;
  handleSkipToSummary: (data: AddPlanReq) => void;
  responseData?: AddPlanRes;
};

function BasicDetails(props: StepProps) {
  const currency = getCurrencyType();
  const {
    onNext,
    setStepValidity,
    mode,
    responseData,
    handleSkipToSummary,
    onBack,
  } = props;
  const formik = useFormikContext<AddPlanReq>();
  const { data: createPlanDetails } = useCreatePlanDetails();
  const addPlanMutating = useIsMutating("addPlan");
  const editPlanMutating = useIsMutating("editPlan");
  const isLoading = addPlanMutating > 0 || editPlanMutating > 0;
  const { errors, touched } = formik;
  const basicDetailFields = [
    "planName",
    "planDescription",
    "planType",
    "planStartDate",
    "poolSize",
    "notes",
    "planTerm",
    "sharesManagedByTrust",
  ];
  let initalCheck = false;
  const basicDetailErrors =
    errors.planName ||
    errors.planDescription ||
    errors.planType ||
    errors.planStartDate ||
    errors.poolSize ||
    errors.poolShares ||
    errors.conversionNumber ||
    errors.notes ||
    errors.planTerm ||
    errors.sharesManagedByTrust;
  function doPartialTouch() {
    formik.setFieldTouched("planName");
    formik.setFieldTouched("planDescription");
    formik.setFieldTouched("planType");
    formik.setFieldTouched("planStartDate");
    formik.setFieldTouched("poolSize");
    formik.setFieldTouched("poolShares");
    formik.setFieldTouched("conversionNumber");
    formik.setFieldTouched("notes");
    formik.setFieldTouched("planTerm");
    formik.setFieldTouched("sharesManagedByTrust");
  }
  useEffect(() => {
    initalCheck = basicDetailFields.some((value) =>
      formik.validateField(value)
    );
  }, []);
  useEffect(() => {
    setStepValidity(basicDetailErrors === undefined);
  }, [basicDetailErrors, initalCheck]);

  useEffect(() => {
    formik.setFieldValue(
      "conversionNumber",
      formik.values.conversionNumber / formik.values.conversionShares
    );
    formik.setFieldValue(
      "poolShares",
      formik.values.poolSize / formik.values.conversionNumber
    );
    formik.setFieldValue("conversionShares", 1);
  }, [formik.values.conversionNumber, formik.values.poolSize]);
  const sharesAvailable =
    responseData?.esopPlanState === PlanState.ACTIVE ||
    responseData?.esopPlanState === PlanState.AMENDMENT
      ? (createPlanDetails?.esopPoolCount || 0) +
        (responseData?.poolSize || 0) * responseData.conversionNumber
      : createPlanDetails?.esopPoolCount || 0;
  const [selectedTrust, setSelectedTrust] = useState<TrustDetails>();
  const showMessage =
    formik.values?.poolSize > 0 && formik.values?.conversionNumber > 0;
  const [trustSource, setTrustSource] = useState("Select Trust");
  const { data: _trustDetails, isFetching } = useTrustDetails();
  const trustDetails = _trustDetails || [];
  const trustNames = trustDetails?.map((v) => v.trustName);
  useEffect(() => {
    const trust = trustDetails.find(
      (t) => t.trustName === formik.values.trustName
    );
    if (trust) {
      formik.setFieldValue("trustId", trust.id);
      setSelectedTrust(trust);
    }
  }, [formik.values.trustName, isFetching]);
  const alreadyUsedInPlan =
    selectedTrust && selectedTrust.id === responseData?.trustId
      ? responseData?.sharesManagedByTrust
      : 0;
  const [alertDialog, setAlertDialog] = useState(false);
  function getUnitTypes(planType: string) {
    if (planType === "ESOP" || planType === "TRUST") {
      return [
        { text: "ESOP", value: "ESOP" },
        { text: "MSOP", value: "MSOP" },
      ];
    } else if (planType === "SAR") {
      return [
        { text: "SAR", value: "SAR" },
        { text: "Phatom Stock", value: "Phatom Stock" },
      ];
    } else if (planType === "RSU") {
      return [{ text: "RSU", value: "RSU" }];
    } else return [];
  }
  return (
    <>
      <VStack className="w-full gap-8 py-6 pr-7">
        <div className="flex justify-start text-lg font-medium">
          <span>Enter the Plan Details</span>
        </div>
        <HStack className="gap-8">
          <div className="flex-1">
            <Label className="text-sm font-normal">Plan Name</Label>
            <Input
              disabled={mode !== "add"}
              className={`${mode !== "add" && "cursor-not-allowed"}`}
              type="text"
              {...formik.getFieldProps("planName")}
            />
            {touched.planName && errors.planName && (
              <Error text={errors.planName} />
            )}
          </div>
        </HStack>
        <HStack className="gap-8 ">
          <div className="flex-1">
            <Label className="text-sm font-normal">Plan Type</Label>
            <Select
              disabled={mode !== "add"}
              isPlaceHolderDisabled={true}
              className={`${mode !== "add" && "cursor-not-allowed"}`}
              options={PlanTypes}
              valueGetter={(o) => o.value}
              textGetter={(o) => o.text}
              {...formik.getFieldProps("planType")}
            />
            {touched.planType && errors.planType && (
              <Error text={errors.planType} />
            )}
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">Plan Start Date</Label>
            <Input
              type="date"
              disabled={mode === "view"}
              className={`${mode === "view" && "cursor-not-allowed"}`}
              {...formik.getFieldProps("planStartDate")}
            />
            {touched.planStartDate && errors.planStartDate && (
              <Error text={errors.planStartDate} />
            )}
          </div>
        </HStack>
        <HStack className="gap-8 ">
          <div className="flex-1">
            <div className="flex items-center justify-between">
              <Label className="text-sm font-normal">Plan Pool Size</Label>
              {mode !== "view" && (
                <Label className="text-xs font-normal text-green-400">
                  {getFormattedValue(sharesAvailable, currency)} shares
                  available{" "}
                </Label>
              )}
            </div>
            <Input
              type="number"
              disabled={mode === "view"}
              className={`${mode === "view" && "cursor-not-allowed"}`}
              {...formik.getFieldProps("poolSize")}
            />
            {showMessage && (
              <Label className="text-xs font-normal">
                {formik.values.poolSize} options will be converted into{" "}
                {parseFloat(formik.values.poolShares.toFixed(2))} shares
              </Label>
            )}
            {touched.poolSize && errors.poolSize && (
              <Error text={errors.poolSize} />
            )}
            {touched.poolSize && errors.poolShares && (
              <Error text={errors.poolShares} />
            )}
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">
              Plan Term (No. of Years)
            </Label>
            <Input
              type="number"
              disabled={mode === "view"}
              className={`${mode === "view" && "cursor-not-allowed"}`}
              {...formik.getFieldProps("planTerm")}
            />
            {touched.planTerm && errors.planTerm && (
              <Error text={errors.planTerm} />
            )}
          </div>
        </HStack>
        <HStack className="gap-8 ">
          <div className="flex-1">
            <Label className="text-sm font-normal">Unit Type</Label>
            <Select
              disabled={mode !== "add"}
              isPlaceHolderDisabled={true}
              className={`${mode !== "add" && "cursor-not-allowed"}`}
              options={getUnitTypes(formik.values.planType)}
              valueGetter={(o) => o.value}
              textGetter={(o) => o.text}
              {...formik.getFieldProps("unitType")}
            />
            {touched.unitType && errors.unitType && (
              <Error text={errors.unitType} />
            )}
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">Unit Name</Label>
            <Input
              disabled={mode === "view"}
              className={`${mode === "view" && "cursor-not-allowed"}`}
              {...formik.getFieldProps("unitName")}
            />
            {touched.unitName && errors.unitName && (
              <Error text={errors.unitName} />
            )}
          </div>
        </HStack>
        <HStack className="gap-8 ">
          <div className="flex-1">
            <Label className="text-sm font-normal">Plan Description</Label>
            <TextArea
              disabled={mode === "view"}
              className={`${mode === "view" && "cursor-not-allowed"}`}
              {...formik.getFieldProps("planDescription")}
            />
            {touched.planDescription && errors.planDescription && (
              <Error text={errors.planDescription} />
            )}
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">Notes</Label>
            <TextArea
              disabled={mode === "view"}
              className={`${mode === "view" && "cursor-not-allowed"}`}
              {...formik.getFieldProps("notes")}
            />
            {touched.notes && errors.notes && <Error text={errors.notes} />}
          </div>
        </HStack>
        {formik.values.planType !== "RSU" && (
          <HStack className="flex flex-row-reverse justify-end gap-4 text-sm font-semibold">
            <SwitchButton
              value={formik.values.isFractional}
              label="Allow Fractional Options"
              disabled={mode !== "add"}
              className={`${mode !== "add" && "cursor-not-allowed"}`}
              onClick={() => {
                formik.setFieldValue(
                  "isFractional",
                  !formik.values.isFractional
                );
              }}
            />
          </HStack>
        )}
        <HStack className="flex flex-row-reverse justify-end gap-4 text-sm font-semibold ">
          <SwitchButton
            className={`${mode === "view" && "cursor-not-allowed"}`}
            value={formik.values.isManagedByTrust}
            label="Plan Managed By Trust?"
            disabled={mode === "view"}
            onClick={() => {
              formik.setFieldValue(
                "isManagedByTrust",
                !formik.values.isManagedByTrust
              );
            }}
          />
        </HStack>
        {formik.values.isManagedByTrust && (
          <VStack className="w-full gap-9">
            <HStack className="gap-8 ">
              <SelectButtonGroup
                options={["Select Trust", "Create New Trust"]}
                value={trustSource}
                disabled={mode === "view"}
                onChange={(value) => {
                  if (mode !== "view") setTrustSource(value);
                }}
              />
            </HStack>
            {trustSource === "Select Trust" && (
              <HStack className="gap-8 ">
                <div className="flex-1">
                  <Label className="text-sm font-normal">Trusts</Label>
                  <Select
                    options={trustNames}
                    disabled={mode === "view"}
                    className={`${mode === "view" && "cursor-not-allowed"}`}
                    {...formik.getFieldProps("trustName")}
                  />
                  {touched.trustName && errors.trustName && (
                    <Error text={errors.trustName} />
                  )}
                </div>
                <div className="flex-1">
                  <div className="flex items-center justify-between">
                    <Label className="text-sm font-normal">
                      No. of shares managed by trust
                    </Label>
                    {selectedTrust && mode !== "view" && (
                      <Label className="text-xs font-normal text-green-400">
                        {selectedTrust.availableShares + alreadyUsedInPlan}{" "}
                        shares available{" "}
                      </Label>
                    )}
                  </div>
                  <Input
                    type="number"
                    disabled={!selectedTrust || mode === "view"}
                    className={`${
                      (!selectedTrust || mode === "view") &&
                      "cursor-not-allowed"
                    }`}
                    {...formik.getFieldProps("sharesManagedByTrust")}
                  />
                  {touched.sharesManagedByTrust &&
                    errors.sharesManagedByTrust && (
                      <Error text={errors.sharesManagedByTrust} />
                    )}
                </div>
              </HStack>
            )}
            {trustSource === "Create New Trust" && (
              <AddOrEditTrust
                mode="Add"
                onClose={() => {}}
                onCreate={(trust) => {
                  formik.setFieldValue("trustId", trust.id);
                  formik.setFieldValue("trustName", trust.trustName);
                  setTrustSource("Select Trust");
                }}
              />
            )}
          </VStack>
        )}
        <HStack className="gap-8 ">
          <VStack className="flex-1 gap-8">
            <div className="space-y-4">
              <Label className="text-sm font-normal">Conversion Ratio</Label>
              <HStack className="items-center">
                <Input
                  type="number"
                  disabled={mode !== "add"}
                  className={`w-48 ${mode !== "add" && "cursor-not-allowed"}`}
                  {...formik.getFieldProps("conversionNumber")}
                />
                <div className="pl-2"> Options</div>
              </HStack>
              {touched.conversionNumber && errors.conversionNumber && (
                <Error text={errors.conversionNumber} />
              )}
              <HStack className="items-center">
                <Input
                  type="number"
                  className="w-48"
                  disabled={true}
                  {...formik.getFieldProps("conversionShares")}
                />
                <div className="pl-2">Shares</div>
              </HStack>
              {showMessage && (
                <Label className="text-xs font-normal">
                  {formik.values.conversionNumber} options will be converted
                  into {parseFloat(formik.values.conversionShares.toFixed(2))}{" "}
                  shares
                </Label>
              )}
            </div>

            <div className={`${mode !== "add" ? "cursor-not-allowed" : ""}`}>
              <Label className="text-sm font-normal">Strike Price Type</Label>
              <HStack className="items-center">
                <Select
                  options={Object.values(StrikePriceType)}
                  disabled={mode !== "add"}
                  textGetter={(o) => o.replace("_", " ")}
                  className="w-48"
                  {...formik.getFieldProps("strikePriceType")}
                />
              </HStack>
            </div>
          </VStack>
        </HStack>
      </VStack>
      <HStack className="justify-end gap-8 py-8 pr-8">
        {mode === "add" && (
          <div className="flex items-center">
            <ButtonSecondary
              className="px-5"
              onClick={() => {
                setAlertDialog(true);
              }}
            >
              Go Back: Template Selection
            </ButtonSecondary>
          </div>
        )}
        {mode === "add" && formik.isValid && (
          <ButtonPrimary1
            className="px-5"
            onClick={() => {
              if (!formik.isValid) {
                toast("There are errors in the form", {
                  autoClose: 2000,
                  type: "error",
                });
              }
              if (formik.isValid && formik.values) {
                handleSkipToSummary(formik.values);
              }
            }}
            loading={isLoading}
          >
            Go to Summary
          </ButtonPrimary1>
        )}
        <div className="flex items-center">
          <ButtonPrimary
            className="px-5"
            onClick={() => {
              if (basicDetailErrors) {
                doPartialTouch();
              } else {
                onNext();
              }
            }}
          >
            Go Next: Vesting Details
          </ButtonPrimary>
        </div>
        {alertDialog && (
          <AlertDialog
            message={`Discard will clear your selection. Are you sure to start fresh?`}
            primaryActionText="Discard"
            onClose={() => setAlertDialog(false)}
            secondaryActionText="Cancel"
            onPrimaryAction={() => {
              formik.resetForm();
              onBack();
            }}
            onSecondaryAction={() => {
              setAlertDialog(false);
            }}
            open={alertDialog}
          />
        )}
      </HStack>
    </>
  );
}

function Template({
  onNext,
  onSelectTemplate,
}: {
  onNext: () => void;
  onSelectTemplate: (template: AddPlanRes) => void;
}) {
  const { data: _planTemplates } = usePlanTemplates();
  const planTemplates = _planTemplates || [];
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTemplate, setSelectedTemplate] = useState<AddPlanRes>();
  const pageSize = 4;
  const templatesToBeDisplayed = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return planTemplates.slice(firstPageIndex, lastPageIndex);
  }, [planTemplates, currentPage, pageSize]);
  function getVestingDetails(vesting: VestingTemplate) {
    let vestingSchedule = "";
    if (vesting.vestingType === VestingType.STANDARD) {
      const totalPeriod = vesting.schedules[0].vestingDuration;
      const interval = vesting.schedules[0].vestingInterval;
      vestingSchedule = `${totalPeriod} Months Vesting every ${interval} Months, ${vesting.cliffPeriod} Months Cliff`;
    } else {
      const isBoth =
        vesting.schedules.some(
          (sc) => sc.vestingTriggerType === TriggerType.TIME
        ) &&
        vesting.schedules.some(
          (sc) => sc.vestingTriggerType === TriggerType.EVENT
        );
      const isTime =
        vesting.schedules.some(
          (sc) => sc.vestingTriggerType === TriggerType.TIME
        ) &&
        !vesting.schedules.some(
          (sc) => sc.vestingTriggerType === TriggerType.EVENT
        );
      const isEvent =
        !vesting.schedules.some(
          (sc) => sc.vestingTriggerType === TriggerType.TIME
        ) &&
        vesting.schedules.some(
          (sc) => sc.vestingTriggerType === TriggerType.EVENT
        );
      for (const schedule of vesting.schedules) {
        if (isTime) {
          vestingSchedule += `${schedule.percentage * 100}% ${
            schedule.vestingDuration
          } Months Vesting every ${schedule.vestingInterval} Months, ${
            vesting.cliffPeriod
          } Months Cliff`;
        } else if (isEvent) {
          vestingSchedule = `Event based Vesting, ${vesting.cliffPeriod} Months Cliff`;
        } else if (isBoth) {
          const totalTimePercentage = _.sumBy(
            vesting.schedules.filter(
              (sc) => sc.vestingTriggerType === TriggerType.TIME
            ),
            (sc) => sc.percentage
          );
          const totalEventPercentage = _.sumBy(
            vesting.schedules.filter(
              (sc) => sc.vestingTriggerType === TriggerType.EVENT
            ),
            (sc) => sc.percentage
          );
          const timeAndCustomSchedule =
            totalEventPercentage === 0 && totalTimePercentage > 0
              ? `Custom Time based Vesting, ${vesting.cliffPeriod} Months Cliff`
              : `${totalEventPercentage * 100}% Event based Vesting and ${
                  totalTimePercentage * 100
                }% Time based Vesting with ${vesting.cliffPeriod} Months Cliff`;
          vestingSchedule =
            totalEventPercentage > 0 && totalTimePercentage === 0
              ? `Event based Vesting, ${vesting.cliffPeriod} Months Cliff`
              : timeAndCustomSchedule;
        }
      }
    }
    return vestingSchedule;
  }
  return (
    <>
      <VStack className="w-full gap-8 py-6 pr-7">
        <div className="flex justify-start text-lg font-medium">
          <span>Pick a Template which best represents your plan</span>
        </div>
        <div
          className={`grid grid-cols-2 overflow-auto min-h-[calc(100vh-30vh)] gap-4 w-full`}
        >
          {templatesToBeDisplayed.map((template, i) => (
            <VStack
              key={i}
              className={`p-4 border-2 rounded-lg max-h-96 ${
                selectedTemplate && selectedTemplate.id === template.id
                  ? " border-orange-501"
                  : ""
              }`}
              onClick={() => {
                if (selectedTemplate && selectedTemplate.id === template.id) {
                  setSelectedTemplate(undefined);
                } else {
                  setSelectedTemplate(template);
                }
              }}
            >
              <HStack className="items-start justify-between text-base font-medium min-h-8">
                {template.planName}
                {selectedTemplate && selectedTemplate.id === template.id && (
                  <Icon
                    icon="ic:round-check"
                    width={26}
                    className="font-bold border rounded-full text-green-401"
                  />
                )}
              </HStack>
              <span className="mt-1 text-gray-201">Plan Details</span>
              <VStack className="gap-6 p-2 mt-1 border rounded-lg">
                <HStack className="justify-between">
                  <span>Plan Type: {template.planType}</span>
                  <span>Tenure: {template.planTerm} years</span>
                </HStack>
                <HStack className="justify-between">
                  <span>Unit Type: {template.unitType}</span>
                  <span>Unit Name: {template.unitName}</span>
                </HStack>
                <HStack>
                  <span>
                    Managed By:{" "}
                    {template.isManagedByTrust ? "Trust" : "Company"}
                  </span>
                </HStack>
              </VStack>
              <span className="mt-3 text-gray-201">Vesting Details</span>
              <VStack className="gap-8 p-3 mt-1 border rounded-lg">
                <HStack className="justify-between">
                  <span>{getVestingDetails(template.vesting)}</span>
                </HStack>
              </VStack>
              <span className="mt-3 text-gray-201">Exercise Details</span>

              <VStack className="gap-8 p-3 mt-1 border rounded-lg">
                <HStack className="justify-center">
                  <span>
                    Expiry On Seperation: {template.forfeitureType}
                    {`${
                      template.forfeitureType === ForfeitureType.MONTHLY
                        ? `| ${template.forfeitureConditions[0].extendedMonths} months`
                        : ""
                    }`}
                  </span>
                </HStack>
              </VStack>
            </VStack>
          ))}
        </div>
        <HStack className="justify-end">
          <Pagination
            currentPage={currentPage}
            totalCount={planTemplates.length}
            pageSize={pageSize}
            onPageChange={(page: number) => setCurrentPage(page)}
          />
        </HStack>
      </VStack>
      <HStack className="justify-end gap-8 py-8 pr-8">
        <ButtonPrimary1 className="px-5" onClick={() => onNext()}>
          Skip
        </ButtonPrimary1>
        <div className="flex items-center">
          <ButtonPrimary
            className="px-5"
            disabled={!selectedTemplate}
            onClick={() => {
              if (selectedTemplate) {
                onSelectTemplate(selectedTemplate);
                toast("Template selected successfully", { type: "success" });
              }
            }}
          >
            Select Template
          </ButtonPrimary>
        </div>
      </HStack>
    </>
  );
}

function VestingSchedules(props: StepProps) {
  const { onBack, onNext, setStepValidity, mode } = props;
  const { data: vestingTemplates } = useVestingTemplates();
  const vestingScheduleTemplates = vestingTemplates?.filter(
    (schedule) => !schedule.isDefault
  );
  const vestingTemplateNames = vestingScheduleTemplates?.map(
    (v) => v.vestingTemplateName
  );
  const formik = useFormikContext<AddPlanReq>();
  const { errors, touched } = formik;
  const [vestingTemplateSource, setVestingTemplateSource] = useState(
    "Select Vesting Template"
  );
  const vestingScheduleErrors = errors.vestingTemplateName;
  const vestingTemplateFields = ["vestingTemplateName"];
  function doPartialTouch() {
    formik.setFieldTouched("vestingTemplateName");
  }
  let initalCheck = false;
  useEffect(() => {
    initalCheck = vestingTemplateFields.some((value) =>
      formik.validateField(value)
    );
  }, []);
  useEffect(() => {
    setStepValidity(vestingScheduleErrors === undefined);
  }, [vestingScheduleErrors, initalCheck]);
  return (
    <>
      <VStack className="w-full py-6 pr-7 gap-9">
        <div className="flex justify-start text-lg font-medium">
          <span>Select a Vesting Schedule</span>
        </div>
        <HStack className="gap-8 ">
          <SelectButtonGroup
            options={["Select Vesting Template", "Create new Vesting Template"]}
            value={vestingTemplateSource}
            onChange={(value) => {
              if (mode !== "view") setVestingTemplateSource(value);
            }}
          />
        </HStack>
        {vestingTemplateSource === "Select Vesting Template" && (
          <HStack className="gap-8 ">
            <div className="flex-1">
              <Label className="text-sm font-normal">Vesting Schedule</Label>
              <Select
                options={vestingTemplateNames || []}
                disabled={mode === "view"}
                className={`${mode === "view" && "cursor-not-allowed"}`}
                {...formik.getFieldProps("vestingTemplateName")}
              />
              {touched.vestingTemplateName && errors.vestingTemplateName && (
                <Error text={errors.vestingTemplateName} />
              )}
            </div>
            <div className="flex-1">
              <ProjectionChart />
            </div>
          </HStack>
        )}
        {vestingTemplateSource === "Create new Vesting Template" && (
          <AddOrEditVestingTemplate
            onClose={() => {}}
            onCreate={(template) => {
              formik.setFieldValue("vestingTemplateId", template.id);
              formik.setFieldValue("vestingTemplateName", template.name);
              setVestingTemplateSource("Select Vesting Template");
            }}
          />
        )}
      </VStack>
      {vestingTemplateSource === "Select Vesting Template" && (
        <HStack className="justify-end gap-8 py-8 pr-8">
          <ButtonPrimary1 className="px-5" onClick={() => onBack()}>
            Go Back
          </ButtonPrimary1>
          <div className="flex items-center">
            <ButtonPrimary
              className="px-5"
              onClick={() => {
                if (vestingScheduleErrors) {
                  doPartialTouch();
                } else {
                  onNext();
                }
              }}
            >
              Go Next: Options Expiry
            </ButtonPrimary>
          </div>
        </HStack>
      )}
    </>
  );
}

function ExerciseWindows(props: StepProps) {
  const { onBack, mode, setStepValidity } = props;
  const formik = useFormikContext<AddPlanReq>();
  const addPlanMutating = useIsMutating("addPlan");
  const editPlanMutating = useIsMutating("editPlan");
  const isLoading = addPlanMutating > 0 || editPlanMutating > 0;
  let initalCheck = false;
  const { touched, errors } = formik;
  const exerciseWindowFields = ["forfeitureType"];
  const exerciseWindowErrors = errors.forfeitureType;
  useEffect(() => {
    initalCheck = exerciseWindowFields.some((value) =>
      formik.validateField(value)
    );
  }, []);
  useEffect(() => {
    setStepValidity(exerciseWindowErrors === undefined);
  }, [exerciseWindowErrors, initalCheck]);
  useEffect(() => {
    if (
      formik.values.forfeitureType === ForfeitureType.MONTHLY &&
      formik.values.forfeitureConditions.length === 0
    ) {
      const defaultForfeiture: ForfeitureCondition = { extendedMonths: 0 };
      formik.setFieldValue("forfeitureConditions", [defaultForfeiture]);
    }
  }, [formik.values.forfeitureType]);
  return (
    <>
      <VStack className="w-full py-6 pr-7 gap-9">
        <div className="flex justify-start text-lg font-medium">
          <span>Options Expiry:</span>
        </div>
        <VStack className="justify-between gap-4">
          <Box className="flex items-center gap-2 font-medium">
            <Label className="content-between text-sm">
              Vested Options Expiry after separation
            </Label>
            <Select
              disabled={mode === "view"}
              isPlaceHolderDisabled={true}
              className={`${mode === "view" && "cursor-not-allowed "} w-80`}
              options={ForfeitureTypes}
              valueGetter={(o) => o.value}
              textGetter={(o) => o.text}
              {...formik.getFieldProps("forfeitureType")}
            />
            {touched.forfeitureType && errors.forfeitureType && (
              <Error text={errors.forfeitureType} />
            )}
          </Box>
          {formik.values.forfeitureType === ForfeitureType.MONTHLY &&
            formik.values.forfeitureConditions.length === 1 && (
              <Box className="flex items-center gap-16">
                <Label className="text-sm">Number of months to exercise</Label>
                <Input
                  {...formik.getFieldProps(
                    "forfeitureConditions[0].extendedMonths"
                  )}
                  className={`${mode === "view" && "cursor-not-allowed"} w-80`}
                  type="number"
                  disabled={mode === "view"}
                />
              </Box>
            )}
        </VStack>
      </VStack>
      <HStack className="justify-end gap-8 py-8 pr-8">
        <ButtonPrimary1 className="px-5" onClick={() => onBack()}>
          Go Back
        </ButtonPrimary1>
        <div className="flex items-center">
          <ButtonPrimary className="px-5" type="submit" loading={isLoading}>
            {mode === "view" ? "Next" : "Submit"}
          </ButtonPrimary>
        </div>
      </HStack>
    </>
  );
}

function Summary(props: StepProps) {
  const errorMessage = useError();
  const { onBack, onNext, responseData: plan, handleEdit, mode } = props;
  const [expanded, setExpanded] = useState(true);
  const [expandedVesting, setExpandedVesting] = useState(false);
  const { data: existingPlanDocs } = useGetAllPlanDocs(plan?.id ?? "");
  function getVestingDetails(vesting: VestingTemplate) {
    let vestingSchedule = "";
    if (vesting.vestingType === VestingType.STANDARD) {
      const totalPeriod = vesting.schedules[0].vestingDuration;
      const interval = vesting.schedules[0].vestingInterval;
      vestingSchedule = `${totalPeriod} Months Vesting every ${interval} Months, ${vesting.cliffPeriod} Months Cliff`;
    } else {
      const isBoth =
        vesting.schedules.some(
          (sc) => sc.vestingTriggerType === TriggerType.TIME
        ) &&
        vesting.schedules.some(
          (sc) => sc.vestingTriggerType === TriggerType.EVENT
        );
      const isTime =
        vesting.schedules.some(
          (sc) => sc.vestingTriggerType === TriggerType.TIME
        ) &&
        !vesting.schedules.some(
          (sc) => sc.vestingTriggerType === TriggerType.EVENT
        );
      const isEvent =
        !vesting.schedules.some(
          (sc) => sc.vestingTriggerType === TriggerType.TIME
        ) &&
        vesting.schedules.some(
          (sc) => sc.vestingTriggerType === TriggerType.EVENT
        );
      for (const schedule of vesting.schedules) {
        if (isTime) {
          vestingSchedule += `${schedule.percentage * 100}% ${
            schedule.vestingDuration
          } Months Vesting every ${schedule.vestingInterval} Months, ${
            vesting.cliffPeriod
          } Months Cliff`;
        } else if (isEvent) {
          vestingSchedule = `Event based Vesting, ${vesting.cliffPeriod} Months Cliff`;
        } else if (isBoth) {
          const totalTimePercentage = _.sumBy(
            vesting.schedules.filter(
              (sc) => sc.vestingTriggerType === TriggerType.TIME
            ),
            (sc) => sc.percentage
          );
          const totalEventPercentage = _.sumBy(
            vesting.schedules.filter(
              (sc) => sc.vestingTriggerType === TriggerType.EVENT
            ),
            (sc) => sc.percentage
          );
          const timeAndCustomSchedule =
            totalEventPercentage === 0 && totalTimePercentage > 0
              ? `Custom Time based Vesting, ${vesting.cliffPeriod} Months Cliff`
              : `${totalEventPercentage * 100}% Event based Vesting and ${
                  totalTimePercentage * 100
                }% Time based Vesting with ${vesting.cliffPeriod} Months Cliff`;
          vestingSchedule =
            totalEventPercentage > 0 && totalTimePercentage === 0
              ? `Event based Vesting, ${vesting.cliffPeriod} Months Cliff`
              : timeAndCustomSchedule;
        }
      }
    }
    return vestingSchedule;
  }

  function getVestingTriggerType(vesting: VestingTemplate) {
    let vestingTriggerType: TriggerType =
      vesting.includeTimeVesting && !vesting.includeEventVesting
        ? TriggerType.TIME
        : TriggerType.EVENT;
    vestingTriggerType =
      vesting.includeTimeVesting && vesting.includeEventVesting
        ? TriggerType.BOTH
        : vestingTriggerType;
    vestingTriggerType =
      !vesting.includeTimeVesting && !vesting.includeEventVesting
        ? TriggerType.TIME
        : vestingTriggerType;
    return vestingTriggerType;
  }
  const [expandedForfeitureSettings, setExpandedForfeitureSettings] =
    useState(false);
  const [expandedGraph, setExpandedGraph] = useState(false);
  const [expandedDocuments, setExpandedDocuments] = useState(false);

  const { mutate: changePlanState, isLoading } = usePlanStateChange();
  function handleClick() {
    if (plan && !plan.id) return;
    changePlanState(
      {
        esopPlanId: plan ? plan.id : "",
        esopPlanState: PlanState.FOR_APPROVAL,
        planPoolSize: plan?.poolSize || 0,
        planStartDate: plan?.planStartDate || "",
      },
      {
        onSuccess: () => {
          toast("State Changed Successfully!", { type: "success" });
          onBack();
        },
        onError: (err: any) => {
          errorMessage.setMessage(err.response.data.reason);
          toast(err.response.data.reason, { type: "error", autoClose: 2000 });
        },
      }
    );
  }
  return (
    <>
      <VStack className="w-full py-6 pr-7 gap-9">
        <div className="flex justify-start text-lg font-medium">
          <span>Plan Summary Page</span>
        </div>
        {plan && (
          <>
            <VStack className="flex-1 border rounded-lg shadow">
              <HStack
                className="items-center justify-between p-4 cursor-pointer"
                onClick={() => setExpanded(!expanded)}
              >
                <h5 className="text-sm font-normal">Plan Details</h5>
                <HStack className="items-center gap-8">
                  {expanded && (
                    <ButtonPrimary1 onClick={() => handleEdit(2)}>
                      Edit
                    </ButtonPrimary1>
                  )}
                  <Box>
                    {expanded ? (
                      <Icon icon="fe:arrow-up" width={16} />
                    ) : (
                      <Icon
                        icon="fe:arrow-up"
                        width={16}
                        className="rotate-180"
                      />
                    )}
                  </Box>
                </HStack>
              </HStack>
              {expanded && (
                <HStack className="flex-wrap grow">
                  {[
                    { field: "Plan Type", value: plan?.planType },
                    {
                      field: "Plan Name",
                      value: plan?.planName,
                    },
                    {
                      field: "Plan Start Date",
                      value:
                        plan?.planStartDate &&
                        formatDisplayDate(
                          new Date(plan?.planStartDate).toUTCString()
                        ),
                    },
                    { field: "Plan Pool Size", value: plan?.poolSize },
                    { field: "Plan Term", value: `${plan?.planTerm} Years` },
                    {
                      field: "Plan Description",
                      value: plan?.planDescription,
                    },
                  ].map(({ field, value }, i) => (
                    <HStack key={i} className="w-1/2 gap-1 p-4">
                      <span className="w-1/3 text-sm font-normal text-gray-400">
                        {field}
                      </span>
                      <span className="w-2/3 text-sm font-normal">
                        {value?.toString()}
                      </span>
                    </HStack>
                  ))}
                  {plan?.isManagedByTrust && (
                    <>
                      {[
                        {
                          field: "Trust Name",
                          value: plan?.trustName,
                        },
                        {
                          field: "Shares Managed By Trust",
                          value: plan?.sharesManagedByTrust,
                        },
                      ].map(({ field, value }, i) => (
                        <HStack key={i} className="w-1/2 gap-1 p-4">
                          <span className="w-1/3 text-sm font-normal text-gray-400">
                            {field}
                          </span>
                          <span className="w-2/3 text-sm font-normal">
                            {value?.toString()}
                          </span>
                        </HStack>
                      ))}
                    </>
                  )}
                  {[
                    {
                      field: "Notes",
                      value: plan?.note,
                    },
                  ].map(({ field, value }, i) => (
                    <HStack key={i} className="w-1/2 gap-1 p-4">
                      <span className="w-1/3 text-sm font-normal text-gray-400">
                        {field}
                      </span>
                      <span className="w-2/3 text-sm font-normal">
                        {value?.toString()}
                      </span>
                    </HStack>
                  ))}
                </HStack>
              )}
            </VStack>
            <VStack className="flex-1 border rounded-lg shadow">
              <HStack
                className="items-center justify-between p-4 cursor-pointer"
                onClick={() => setExpandedVesting(!expandedVesting)}
              >
                <h5 className="text-sm font-normal">Vesting Schedule</h5>
                <HStack className="items-center gap-8">
                  {expandedVesting && (
                    <ButtonPrimary1 onClick={() => handleEdit(3)}>
                      Edit
                    </ButtonPrimary1>
                  )}
                  <Box>
                    {expandedVesting ? (
                      <Icon icon="fe:arrow-up" width={16} />
                    ) : (
                      <Icon
                        icon="fe:arrow-up"
                        width={16}
                        className="rotate-180"
                      />
                    )}
                  </Box>
                </HStack>
              </HStack>
              {expandedVesting && (
                <HStack className="flex-wrap grow">
                  {[
                    {
                      field: "Vesting Details",
                      value: getVestingDetails(plan?.vesting),
                    },
                    {
                      field: "Vesting Schedule Name",
                      value: plan?.vesting.vestingTemplateName,
                    },
                    {
                      field: "Vesting Trigger Type",
                      value: getVestingTriggerType(plan?.vesting),
                    },
                    {
                      field: "Vesting Type",
                      value: plan?.vesting.vestingType,
                    },
                  ].map(({ field, value }, i) => (
                    <HStack key={i} className="w-1/2 gap-1 p-4">
                      <span className="w-1/3 text-sm font-normal text-gray-400">
                        {field}
                      </span>
                      <span className="w-2/3 text-sm font-normal">
                        {value?.toString()}
                      </span>
                    </HStack>
                  ))}
                </HStack>
              )}
            </VStack>
            <VStack className="flex-1 border rounded-lg shadow">
              <HStack
                className="items-center justify-between p-4 cursor-pointer"
                onClick={() =>
                  setExpandedForfeitureSettings(!expandedForfeitureSettings)
                }
              >
                <h5 className="text-sm font-normal">Exercise Details</h5>
                <HStack className="items-center gap-8">
                  {expandedForfeitureSettings && (
                    <ButtonPrimary1 onClick={() => handleEdit(4)}>
                      Edit
                    </ButtonPrimary1>
                  )}
                  <Box>
                    {expandedForfeitureSettings ? (
                      <Icon icon="fe:arrow-up" width={16} />
                    ) : (
                      <Icon
                        icon="fe:arrow-up"
                        width={16}
                        className="rotate-180"
                      />
                    )}
                  </Box>
                </HStack>
              </HStack>
              {expandedForfeitureSettings && (
                <HStack className="flex-wrap grow">
                  {[
                    {
                      field: "Forfeiture Type",
                      value: plan?.forfeitureType,
                    },
                    {
                      field: "Extended Months",
                      value:
                        plan?.forfeitureType === ForfeitureType.MONTHLY
                          ? plan.forfeitureConditions[0].extendedMonths
                          : "-",
                    },
                  ].map(({ field, value }, i) => (
                    <HStack key={i} className="w-1/2 gap-1 p-4">
                      <span className="w-1/3 text-sm font-normal text-gray-400">
                        {field}
                      </span>
                      <span className="w-2/3 text-sm font-normal">
                        {value?.toString()}
                      </span>
                    </HStack>
                  ))}
                </HStack>
              )}
            </VStack>
            <VStack className="flex-1 border rounded-lg shadow">
              <HStack
                className="justify-between p-4 cursor-pointer"
                onClick={() => setExpandedGraph(!expandedGraph)}
              >
                <h5 className="text-sm font-normal">Vesting Graph</h5>
                <Box>
                  {expandedGraph ? (
                    <Icon icon="fe:arrow-up" width={16} />
                  ) : (
                    <Icon
                      icon="fe:arrow-up"
                      width={16}
                      className="rotate-180"
                    />
                  )}
                </Box>
              </HStack>
              {expandedGraph && (
                <HStack className="flex-1">
                  <ProjectionChart />
                </HStack>
              )}
            </VStack>
            {existingPlanDocs && existingPlanDocs.length > 0 && (
              <VStack className="flex-1 border rounded-lg shadow">
                <HStack
                  className="justify-between p-4 cursor-pointer"
                  onClick={() => setExpandedDocuments(!expandedDocuments)}
                >
                  <h5 className="text-sm font-normal">Documents</h5>
                  <Box>
                    {expandedDocuments ? (
                      <Icon icon="fe:arrow-up" width={16} />
                    ) : (
                      <Icon
                        icon="fe:arrow-up"
                        width={16}
                        className="rotate-180"
                      />
                    )}
                  </Box>
                </HStack>
                {expandedDocuments && (
                  <PlanDocument
                    planId={plan.id}
                    isViewMode={true}
                    existingPlanDocs={existingPlanDocs || []}
                  />
                )}
              </VStack>
            )}
            {mode !== "view" && (
              <HStack className="items-center w-full h-24 px-6 border-2 border-yellow-400 border-dashed rounded-md bg-yellow-50">
                <div className="flex justify-center mx-4 rounded-full h-11 w-11 bg-amber-200">
                  <Icon
                    icon="ant-design:exclamation-outlined"
                    color="#ffc700"
                    height={42}
                  />
                </div>

                <div className="mx-4">
                  <p className="text-[16px] font-medium text-slate-900 ">
                    Your Plan &quot;{plan?.planName}&quot; has been{" "}
                    {plan.esopPlanState !== PlanState.CONSTRUCTED
                      ? "amended."
                      : "constructed."}
                  </p>
                  {plan.esopPlanState === PlanState.CONSTRUCTED && (
                    <p className="text-slate-500 font-medium text-[14px]">
                      Take next step if you are fine with details above,
                      {!isLoading ? (
                        <span
                          onClick={() => handleClick()}
                          className="mx-2 underline cursor-pointer text-orange-501"
                        >
                          please send <b>For Approval</b>
                        </span>
                      ) : (
                        <span className="mx-2 text-orange-501">
                          Please Wait...
                          <CircularProgress size={18} />
                        </span>
                      )}
                    </p>
                  )}
                </div>
              </HStack>
            )}
          </>
        )}
      </VStack>
      <HStack className="justify-between p-8">
        <div></div>
        <div className="flex items-center">
          <ButtonPrimary className="px-5" onClick={() => onNext()}>
            Close
          </ButtonPrimary>
        </div>
      </HStack>
    </>
  );
}

function ProjectionChart() {
  const formik = useFormikContext<AddPlanReq>();
  const { data: _vestingTemplates } = useVestingTemplates();
  const vestingTemplates = _vestingTemplates || [];
  const template = vestingTemplates.find(
    (template) =>
      template.vestingTemplateName === formik.values.vestingTemplateName
  );
  if (template?.schedules) {
    template.schedules.forEach((s) => {
      s.percentage /= 100;
    });
  }
  const isFractional = vestingTemplates.some(
    (template) => template.isFractional
  );
  const vestings = generateProjections({
    cliffPeriod: template?.cliffPeriod || 12,
    optionsGranted: 100,
    schedules: template?.schedules || [],
    vestingStartDate: new Date(new Date().getFullYear(), 0, 1),
    vestingType: template?.vestingType || VestingType.STANDARD,
    isFractional,
  });

  const data: ChartData<"bar", number[], unknown> = {
    labels: vestings.map((v) => format(v.date, "MMM yy")),
    datasets: [
      {
        label: "to date",
        data: vestings.map((v) =>
          isFractional
            ? parseFloat(
                (v.accumulatedVestedOptionsForGrant - v.vestedOptions).toFixed(
                  4
                )
              )
            : v.accumulatedVestedOptionsForGrant - v.vestedOptions
        ),
        backgroundColor: "#F8B195",
      },
      {
        label: "on date",
        data: vestings.map((v) =>
          isFractional
            ? parseFloat(v.vestedOptions.toFixed(4))
            : v.vestedOptions
        ),
        backgroundColor: "#F67280",
        borderRadius: 4,
      },
    ],
  };

  return <BarChart data={data} isFractional={isFractional} />;
}
