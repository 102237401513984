import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { HStack, VStack } from "../../components/utils";
import BCHeader from "../../shared/BCHeader";
import GrantDetailsStepper from "./GrantDetailsStepper";
import GrantDetailsNavigation from "./GrantDetailsNavigation";
import { getEditGrantDetails } from "../../api/Esop";
import { useGetEditGrantDetails } from "../../queries";
import { useGrantDetailsStore } from "../../store/useGrantDetailsStore";
import { VestingType } from "../../types/VestingTemplate";
import { StrikePriceType } from "../../types/EsopPlan";

const AddGrantDetails = () => {
  const navigate = useNavigate();
  const { mode, id } = useParams();
  const data = useGrantDetailsStore();

  const {
    data: editDetails,
    isFetching,
    refetch,
  } = useGetEditGrantDetails(id ?? "");

  useEffect(() => {
    if (editDetails) {
      data.clear();
      data.setActualVestingDay(editDetails.actualVestingDay);
      data.setAmount(editDetails.amount);
      const conversionRatio = parseFloat(
        (1 / (editDetails.conversionRatio || 1)).toFixed(4)
      );
      data.setConversionRatio(
        `${conversionRatio} ${
          conversionRatio > 1 ? "Options" : "Option"
        } : 1 Share`
      );
      data.setDateOfGrant(editDetails.dateOfGrant);
      data.setEmailsTosend(editDetails.emailsToSend);
      data.setGrantPrice(editDetails.grantPrice);
      data.setGrantedBy(editDetails.grantedBy.toString());
      data.setGrantType(editDetails.grantType);
      data.setMaxSalary(editDetails.maxSalary);
      data.setMinSalary(editDetails.minSalary);
      data.setModelMaxValueOfOptions(editDetails.modelMaxValueOfOptions);
      data.setModelValueOfOptions(editDetails.modelValueOfOptions);
      data.setModellingId(editDetails.modellingId);
      data.setOptionMultiplier(editDetails.optionMultiplier);
      data.setOptionsGranted(editDetails.optionsGranted);
      data.setNote(editDetails?.note || "");
      data.setPlan(
        mode === "clone"
          ? {
              ...editDetails.plan,
              optionsReserved:
                editDetails.plan.optionsReserved - editDetails.optionsGranted,
            }
          : editDetails.plan
      );
      data.setSelectedExistingTemplate(
        mode === "clone" ? true : editDetails.selectedExistingTemplate
      );
      data.setSharePrice(editDetails.sharePrice);
      data.setValuationId(editDetails.valuationId);
      data.setValueOfOptions(
        editDetails?.plan?.strikePriceType === StrikePriceType.SHARE_PRICE
          ? editDetails.valueOfOptions * editDetails.plan.conversionShares
          : editDetails.valueOfOptions
      );
      data.setVestingDate(editDetails.vestingDate);
      data.setVestingDateType(editDetails.vestingDateType);
      if (
        !(
          editDetails.vestingTemplate.vestingType === VestingType.MANUAL &&
          mode === "clone"
        )
      )
        data.setVestingTemplate(editDetails.vestingTemplate);
      if (mode === "edit") {
        data.setCustomizedGrantLetter(editDetails.customizedGrantLetter);
        data.setEmployee(editDetails.employee);
        data.setGrantId(editDetails.grantId);
        data.setVestingScheduleData(editDetails.vestingScheduleData);
        data.setManualTemplateName(
          editDetails.vestingTemplate.vestingTemplateName
        );
      } else if (mode === "clone") {
        navigate("/options/grant/add");
      }
    }
  }, [isFetching]);

  const [currentStep, setCurrentStep] = useState(
    mode === "add" || mode === "clone" ? 1 : 4
  );

  function onStepChange(step?: number) {
    setCurrentStep(step || parseInt(`${currentStep}`, 10) + 1);
  }
  function onBackClick() {
    if (currentStep !== 1) {
      setCurrentStep(parseInt(`${currentStep}`, 10) - 1);
    }
  }
  return (
    <VStack className="h-full gap-4">
      <div className={`flex items-baseline justify-between`}>
        <BCHeader
          bcTitle={"Equity Plans & Grants"}
          className="py-1"
          bcSubTitle={
            mode === "add"
              ? "Create Grant"
              : mode === "edit"
              ? "Edit Grant"
              : "View Grant"
          }
        />
      </div>
      <HStack className="flex h-full min-h-full gap-4 ">
        <VStack className="flex w-[25%] bg-white rounded-md min-w-72 ">
          <GrantDetailsStepper
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        </VStack>
        <VStack className="flex w-[75%] bg-white rounded-md min-w-96">
          <GrantDetailsNavigation
            currentStep={currentStep}
            onStepChange={onStepChange}
            onBackClick={onBackClick}
          />
        </VStack>
      </HStack>
    </VStack>
  );
};

export default AddGrantDetails;
