export const TableId = {
  planTable: "planTable",
  grantTable: "grantTable",
  employeeTable: "employeeTable",
  vestingTable: "vestingTable",
  milestoneTable: "milestoneTable",
  completeMilestoneTable: "completeMilestoneTable",
  valuationTable: "valuationTable",
  buybackTable: "buybackTable",
  exerciseAndLiquiDateTable: "exerciseAndLiquiDateTable",
  exerciseTable: "exerciseTable",
  exerciseRequestTable: "exerciseRequestTable",
};
