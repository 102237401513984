import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

import { useNavigate } from "react-router";
import {
  CellClickedEvent,
  ColDef,
  GetQuickFilterTextParams,
  GridApi,
  INumberCellEditorParams,
  SideBarDef,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { toast } from "react-toastify";
import AlertDialog from "../../components/shared/AlertDialog";

import { BulkResignationDto, Employee } from "../../types/Employee";

import {
  Box,
  ButtonPrimary,
  ButtonSecondary,
  HStack,
  IconCTAButton,
  VStack,
} from "../../components/utils";

import { useGetFilterStates } from "../../queries/gridUtils";

import { TableId } from "../../constants/TableIdConstants";
import { useTableStateManagement } from "../../components/shared/TableHook";

import useIsMobile from "../../utils/detectDevice";
import { formatDisplayDate } from "../../utils/date";
import {
  useBulkResignationConfirm,
  useBulkResignationExcelValidation,
} from "../../queries";

function BulkResignationSummary({
  summaryData,
  onDiscard,
}: {
  summaryData: BulkResignationDto[];
  onDiscard: Function;
}) {
  const {
    mutate: bulkResignationExcelValidation,
    error: bulkResignationExcelValidationError,
  } = useBulkResignationExcelValidation();

  const [isEdited, setIsEdited] = useState<boolean>(false);

  const { mutate: bulkResignationExcelConfirm, error: bulkResignationConfirm } =
    useBulkResignationConfirm();
  const [bulkResignationSummaryData, setBulkResignationSummaryData] =
    useState<BulkResignationDto[]>(summaryData);
  const [errors, setErrors] = useState<number>(0);
  const gridApi = useRef<GridApi | any>(null);
  // route params logic
  const isPlaceholderData = false;
  const { isMobile } = useIsMobile();

  useEffect(() => {
    if (gridApi.current) {
      gridApi.current?.api?.setRowData(bulkResignationSummaryData);

      gridApi.current?.api?.refreshCells();
    }
    const filteredWithErrors = bulkResignationSummaryData.filter(
      (item: any) => item.errors && item.errors.trim() !== ""
    );
    setErrors(filteredWithErrors.length);
  }, [bulkResignationSummaryData, gridApi.current]);

  const [alertDialog, setAlertDialog] = useState(false);

  const navigate = useNavigate();
  const sideBar = useMemo<SideBarDef>(
    () => ({
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          width: 225,
          maxWidth: 225,
          toolPanelParams: {
            suppressRowGroups: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressValues: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
      ],
    }),
    []
  );
  const filterparams = {
    filterParams: {
      filterOptions: [
        "lessThan",
        {
          displayKey: "lessThanWithNulls",
          displayName: "Less Than with Nulls",
          predicate: ([filterValue]: any, cellValue: number | null) =>
            cellValue == null || cellValue < filterValue,
        },
        "greaterThan",
        {
          displayKey: "greaterThanWithNulls",
          displayName: "Greater Than with Nulls",
          predicate: ([filterValue]: any, cellValue: number | null) =>
            cellValue == null || cellValue > filterValue,
        },
        {
          displayKey: "betweenExclusive",
          displayName: "Between (Exclusive)",
          predicate: ([fv1, fv2]: any, cellValue: number | null) =>
            cellValue == null ||
            // eslint-disable-next-line no-mixed-operators
            (fv1 < cellValue && fv2 > cellValue),
          numberOfInputs: 2,
        },
      ],
    },
  };

  const { data, refetch } = useGetFilterStates("EMPLOYEES");

  //   const handleCellClick = (cellParams: CellClickedEvent<any, any>) => {
  //     if (cellParams.column.getColId() !== "action-column") {
  //       const rowData = employeeTableData.find(
  //         (row) =>
  //           row.employeeIdentificationString === cellParams?.data?.employeeId
  //       );
  //       if (rowData) {
  //         navigate(`/options/employeeDetails/${rowData.id}`);
  //       }
  //     }
  //   };

  const isValidDate = (date: string) =>
    Number.isNaN(date) &&
    new Date(date).toString() !== "Invalid Date" &&
    !Number.isNaN(Date.parse(date));
  const normaliseValue = (value: string | number) => {
    if (typeof value === "number") return value;
    if (typeof value === "string") {
      // check if it can be converted to number first
      // assume if commas are there
      if (isValidDate(value)) return new Date(value).valueOf();
      try {
        value = value.replaceAll(",", "");
      } catch (TypeError) {
        value = value.replace(/,./g, "");
      }
      return parseFloat(value);
    }
    return value;
  };
  const sortComparator = (
    valueA: number | string,
    valueB: number | string,
    ..._: any[]
  ) => normaliseValue(valueA) - normaliseValue(valueB);
  const stringToNumberConvertor = (formattedNumber: string) =>
    parseInt(formattedNumber.toString().replaceAll(",", ""), 10);

  const handleSelectionChanged = (item: any) => {
    const selectedRows = item.api.getSelectedRows();
  };
  const {
    filterCondition,
    setFilterCondition,
    isSaving,
    uploadFilter,
    uploadColumn,
  } = useTableStateManagement(gridApi, TableId.employeeTable);

  const componentsRegistery = useMemo(
    () => ({
      hrId: memo(HrIdRender),
      employeeName: memo(EmployeeNameRender),
      resignationDate: memo(ResignationDateRender),
      lastWorkingDate: memo(LastworkingDateRender),
      pauseVesting: memo(PauseVestingRender),
      pauseVestingDate: memo(PauseVestingDateRender),
      lapsedImmediately: memo(LapsedImmediatelyRender),
      warnings: memo(WarningsRender),
      errors: memo(ErrorsRender),

      note: memo(NoteRender),
    }),
    []
  );

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      wrapText: true,
      flex: 1,
      autoHeight: true,
      initialWidth: 150,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      cellClass: "multiline text-xs font-medium text-gray-dark leading-5",
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      comparator: sortComparator,
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
    }),
    []
  );
  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "HRID",
        field: "hrId",
        cellRenderer: "hrId",
        cellStyle: { paddingTop: "15px", lineHeight: "0" },
        sortable: true,
        menuTabs: ["filterMenuTab"],
        comparator(valueA, valueB, ..._other) {
          return valueA.localeCompare(valueB);
        },

        editable: true,
        filter: "agSetColumnFilter",

        cellEditorSelector: (params: any) => ({
          component: "agTextCellEditor",
          popup: false,
          cellEditorPopupPosition: "under",
        }),

        cellEditorParams: {
          min: 0,
          max: 100,
          precision: 4,
          step: 0.01,
          showStepperButtons: true,
        } as INumberCellEditorParams,
        filterParams: {
          buttons: ["reset"],
          defaultToNothingSelected: true,
          suppressSelectAll: false,
          suppressMiniFilter: false,
          excelMode: "windows",
          comparator: sortComparator,
          keyCreator: (params: any) => {
            const eventStatus = params.data?.employeeName;
            return eventStatus;
          },
          valueFormatter: (params: any) => {
            const eventStatus = params.data?.employeeName;
            return eventStatus;
          },
          valueGetter: (params: any) => params.data?.employeeName,
        },
      },
      {
        headerName: "EMPLOYEE NAME",
        field: "employeeName",
        cellRenderer: "employeeName",
        cellStyle: { paddingTop: "15px", lineHeight: "0" },
        sortable: true,
        comparator(valueA, valueB, ..._other) {
          return valueA.localeCompare(valueB);
        },
        editable: true,
        filter: "agSetColumnFilter",
        menuTabs: ["filterMenuTab"],

        cellEditorSelector: (params: any) => ({
          component: "agTextCellEditor",
          popup: false,
          cellEditorPopupPosition: "under",
        }),

        cellEditorParams: {
          min: 0,
          max: 100,
          precision: 4,
          step: 0.01,
          showStepperButtons: true,
        } as INumberCellEditorParams,

        filterParams: {
          buttons: ["reset"],
          defaultToNothingSelected: true,
          suppressSelectAll: false,
          suppressMiniFilter: false,
          excelMode: "windows",
          comparator: sortComparator,
          keyCreator: (params: any) => {
            const eventStatus = params.data?.employeeName;
            return eventStatus;
          },
          valueFormatter: (params: any) => {
            const eventStatus = params.data?.employeeName;
            return eventStatus;
          },
          valueGetter: (params: any) => params.data?.employeeName,
        },
      },
      {
        headerName: "RESIGNATION DATE",
        field: "resignationDate",
        filter: "agDateColumnFilter",
        editable: true,

        cellEditorSelector: (params: any) => ({
          component: "agDateCellEditor",
          popup: false,
          cellEditorPopupPosition: "under",
        }),

        cellEditorParams: {
          min: 0,
          max: 100,
          precision: 4,
          step: 0.01,
          showStepperButtons: true,
        },

        filterParams: {
          comparator: (dateFromFilter: Date, cellValue: any) => {
            const filterValue = new Date(dateFromFilter);
            const newValue = new Date(cellValue);
            filterValue.setHours(0, 0, 0, 0);
            newValue.setHours(0, 0, 0, 0);
            if (dateFromFilter.getTime() === newValue.getTime()) return 0;
            return dateFromFilter.getTime() > newValue.getTime() ? -1 : 1;
          },
        },
        cellRenderer: "resignationDate",
        cellStyle: { paddingTop: "15px", lineHeight: "0" },
        sortable: true,
        menuTabs: ["filterMenuTab"],
        valueGetter: (params: any) => {
          const date = params.data[params.colDef.field];
          return new Date(date).toLocaleDateString();
        },

        valueSetter: (params: any) => {
          const newDate = new Date(params.newValue);
          const isValidDate = !Number.isNaN(Date.parse(params.newValue));

          if (isValidDate) {
            newDate.setHours(0, 0, 0, 0);

            const normalizedDate = new Date(
              Date.UTC(
                newDate.getFullYear(),
                newDate.getMonth(),
                newDate.getDate()
              )
            );

            params.data[params.colDef.field] = normalizedDate.toISOString();

            return true;
          }

          return false;
        },
      },
      {
        headerName: "LAST WORKING DATE",
        field: "lastWorkingDate",
        filter: "agDateColumnFilter",
        cellEditorSelector: (params: any) => ({
          component: "agDateCellEditor",
          popup: false,
          cellEditorPopupPosition: "under",
        }),
        editable: true,

        cellEditorParams: {
          min: 0,
          max: 100,
          precision: 4,
          step: 0.01,
          showStepperButtons: true,
        },

        filterParams: {
          comparator: (dateFromFilter: Date, cellValue: any) => {
            const filterValue = new Date(dateFromFilter);
            const newValue = new Date(cellValue);
            filterValue.setHours(0, 0, 0, 0);
            newValue.setHours(0, 0, 0, 0);
            if (dateFromFilter.getTime() === newValue.getTime()) return 0;
            return dateFromFilter.getTime() > newValue.getTime() ? -1 : 1;
          },
        },

        cellRenderer: "lastWorkingDate",
        cellStyle: { paddingTop: "15px", lineHeight: "0" },
        sortable: true,
        menuTabs: ["filterMenuTab"],

        valueGetter: (params: any) => {
          const date = params.data[params.colDef.field];
          return new Date(date).toLocaleDateString();
        },

        valueSetter: (params: any) => {
          const newDate = new Date(params.newValue);
          const isValidDate = !Number.isNaN(Date.parse(params.newValue));

          if (isValidDate) {
            newDate.setHours(0, 0, 0, 0);

            const normalizedDate = new Date(
              Date.UTC(
                newDate.getFullYear(),
                newDate.getMonth(),
                newDate.getDate()
              )
            );

            params.data[params.colDef.field] = normalizedDate.toISOString();

            return true;
          }

          return false;
        },
      },
      {
        headerName: "PAUSE VESTING",
        field: "pauseVesting",
        cellRenderer: "pauseVesting",
        cellStyle: { paddingTop: "15px", lineHeight: "0" },
        sortable: true,
        editable: true,
        filter: "agSetColumnFilter",
        menuTabs: ["filterMenuTab"],

        cellEditorSelector: (params: any) => ({
          component: "agTextCellEditor",
          popup: false,
          cellEditorPopupPosition: "under",
        }),

        cellEditorParams: {
          min: 0,
          max: 100,
          precision: 4,
          step: 0.01,
          showStepperButtons: true,
        } as INumberCellEditorParams,
        filterParams: {
          buttons: ["reset"],
          defaultToNothingSelected: true,
          suppressSelectAll: false,
          suppressMiniFilter: false,
          excelMode: "windows",
        },
      },
      {
        headerName: "PAUSE VESTING DATE",
        field: "pauseVestingDate",
        filter: "agDateColumnFilter",
        cellEditorSelector: (params: any) => ({
          component: "agDateCellEditor",
          popup: false,
          cellEditorPopupPosition: "under",
        }),
        editable: true,

        cellEditorParams: {
          min: 0,
          max: 100,
          precision: 4,
          step: 0.01,
          showStepperButtons: true,
        },

        filterParams: {
          comparator: (dateFromFilter: Date, cellValue: any) => {
            const filterValue = new Date(dateFromFilter);
            const newValue = new Date(cellValue);
            filterValue.setHours(0, 0, 0, 0);
            newValue.setHours(0, 0, 0, 0);
            if (dateFromFilter.getTime() === newValue.getTime()) return 0;
            return dateFromFilter.getTime() > newValue.getTime() ? -1 : 1;
          },
        },

        cellRenderer: "pauseVestingDate",
        cellStyle: { paddingTop: "15px", lineHeight: "0" },
        sortable: true,
        menuTabs: ["filterMenuTab"],

        valueGetter: (params: any) => {
          const date = params.data[params.colDef.field];
          return new Date(date).toLocaleDateString();
        },

        valueSetter: (params: any) => {
          const newDate = new Date(params.newValue);
          const isValidDate = !Number.isNaN(Date.parse(params.newValue));

          if (isValidDate) {
            newDate.setHours(0, 0, 0, 0);

            const normalizedDate = new Date(
              Date.UTC(
                newDate.getFullYear(),
                newDate.getMonth(),
                newDate.getDate()
              )
            );

            params.data[params.colDef.field] = normalizedDate.toISOString();

            return true;
          }

          return false;
        },
      },
      {
        headerName: "LAPSED IMMEDIATELY ON LWD",
        field: "lapsedImmediately",
        filter: "agSetColumnFilter",
        editable: true,
        menuTabs: ["filterMenuTab"],

        cellEditorSelector: (params: any) => ({
          component: "agTextCellEditor",
          popup: false,
          cellEditorPopupPosition: "under",
        }),

        cellEditorParams: {
          min: 0,
          max: 100,
          precision: 4,
          step: 0.01,
          showStepperButtons: true,
        } as INumberCellEditorParams,
        cellRenderer: "lapsedImmediately",
        cellStyle: { paddingTop: "15px", lineHeight: "0" },
        sortable: true,
        filterParams: {
          buttons: ["reset"],
          defaultToNothingSelected: true,
          suppressSelectAll: false,
          suppressMiniFilter: false,
          excelMode: "windows",
        },
      },
      {
        headerName: "WARNINGS",
        field: "warnings",
        filter: "agTextColumnFilter",
        cellRenderer: "warnings",
        cellStyle: { paddingTop: "15px", lineHeight: "0" },
        sortable: true,
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: "ERRORS",
        field: "errors",
        filter: "agTextColumnFilter",
        cellRenderer: "errors",
        cellStyle: { paddingTop: "15px", lineHeight: "0" },
        sortable: true,
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: "NOTE",
        field: "note",
        filter: "agTextColumnFilter",
        cellRenderer: "note",
        cellStyle: { paddingTop: "15px", lineHeight: "0" },
        sortable: true,
        menuTabs: ["filterMenuTab"],
      },
    ],
    []
  );

  const rowData = useMemo(
    () =>
      bulkResignationSummaryData?.map((template) => ({
        hrId: template.hrId,
        employeeName: template.employeeName,
        resignationDate: template.resignationDate,
        lastWorkingDate: template.lastWorkingDate,
        pauseVesting: template.pauseVesting,
        pauseVestingDate: template.pauseVestingDate,
        lapsedImmediately: template.lapsedImmediately,
        warnings: template.warnings,
        errors: template.errors,
        note: template.note,
      })),
    [summaryData]
  );
  const onPageSizeChanged = useCallback(() => {
    const value = (document.getElementById("page-size") as HTMLInputElement)
      .value;
    gridApi.current?.api?.paginationSetPageSize(Number(value));
  }, []);
  const onGridReady = (params: any) => {
    gridApi.current = params;
  };

  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    let filterModel = grid.api.getFilterModel();

    const isOnlyEmptySetFilters =
      Object.keys(filterModel).length > 0 &&
      Object.values(filterModel).every(
        (filter: any) =>
          filter.filterType === "set" &&
          Array.isArray(filter.values) &&
          filter.values.length === 0
      );

    if (isOnlyEmptySetFilters) {
      grid.api.setFilterModel(null);
      filterModel = {};
    }

    setIsFilterApplied(filtersApplied);

    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
    uploadFilter(filterModel);
  };

  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api.openToolPanel(key);
        else gridApi?.current?.api.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) gridApi?.current?.api.openToolPanel(key);
        else gridApi?.current?.api.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };

  const handleValidation = () => {
    const allRows: any = [];
    gridApi.current?.api?.forEachNode((node: any) => {
      allRows.push(node.data);
    });

    bulkResignationExcelValidation(allRows, {
      onSuccess: (data) => {
        setBulkResignationSummaryData(data);
        toast("Validation successful", { type: "success" });
      },
      onError: (error) => {
        const errorMessage = "Something went wrong. Please try again.";

        toast(errorMessage, { type: "error" });
      },
    });
    setIsEdited(false);
  };

  const handleConfirmClick = () => {
    const allRows: any = [];
    gridApi.current?.api?.forEachNode((node: any) => {
      allRows.push(node.data);
    });

    bulkResignationExcelConfirm(allRows, {
      onSuccess: (data) => {
        toast("Bulk resignation completed successfully", { type: "success" });
        navigate("/options/allEmployees");
      },
      onError: (error) => {
        const errorMessage = error.response.data.errorMessage;

        toast(errorMessage, { type: "error" });
      },
    });
  };

  const onCellValueChanged = (params: any) => {
    setIsEdited(true);
  };

  return (
    <VStack className="w-full">
      <HStack className="py-4 "></HStack>
      <HStack className="items-center justify-end bg-white rounded-md">
        <HStack className="items-center justify-end gap-4 pb-4">
          <HStack className="items-center"></HStack>
          {alertDialog && (
            <AlertDialog
              message={
                "Are you sure you want to proceed with bulk resignation? This action cannot be undone."
              }
              onClose={() => setAlertDialog(false)}
              onPrimaryAction={() => {
                handleConfirmClick();
              }}
              onSecondaryAction={() => {
                setAlertDialog(false);
              }}
              open={alertDialog}
            />
          )}
          <IconCTAButton
            value={"Columns"}
            onClick={() => openToolPanel("columns")}
            iconName={"fluent:column-triple-edit-20-regular"}
            className={`px-4 font-medium items-center flex flex-row ${
              isColumnOpen ? "text-orange-501" : "text-gray-400"
            }`}
            selected={isColumnOpen}
          />
          <IconCTAButton
            value={"Filters"}
            onClick={() => openToolPanel("filters")}
            iconName={"material-symbols:filter-alt"}
            className={`px-4 font-medium items-center flex flex-row ${
              isFilterOpen ? "text-orange-501" : "text-gray-400"
            }`}
            selected={isFilterOpen}
          />
        </HStack>
      </HStack>

      <HStack className="justify-between w-full">
        <Box
          style={{
            height: `${
              (rowData.length >= 10 ? 10 : rowData.length + 3) * 60
            }px`,
          }}
          className="w-full h-full max-h-full overflow-x-auto bg-black ag-theme-material"
        >
          <AgGridReact
            onFilterChanged={onAgGridFilterChanged}
            onColumnEverythingChanged={uploadColumn}
            onColumnResized={uploadColumn}
            components={componentsRegistery}
            alwaysShowHorizontalScroll
            rowClass={"border-t border-dashed cursor-pointer hover:bg-slate-50"}
            alwaysMultiSort
            onGridReady={onGridReady}
            sideBar={sideBar}
            animateRows={true}
            defaultColDef={defaultColDef}
            rowData={rowData}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            onCellValueChanged={onCellValueChanged}
            suppressCellFocus={true}
            suppressMenuHide={true}
            columnDefs={columnDefs}
            singleClickEdit={true}
            stopEditingWhenCellsLoseFocus={true}
            rowMultiSelectWithClick={true}
            rowSelection="multiple"
            onSelectionChanged={handleSelectionChanged}
            suppressRowClickSelection={true}
            pagination={true}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;   margin-top: 50px;">No Rows To Show</span>'
            }
          ></AgGridReact>
        </Box>
      </HStack>
      <Box
        className={`flex flex-row py-4 ${
          isMobile ? "justify-center" : "justify-end gap-4"
        }`}
      >
        <ButtonSecondary onClick={() => onDiscard(false)}>
          Discard
        </ButtonSecondary>
        {errors > 0 || isEdited ? (
          <ButtonPrimary onClick={() => handleValidation()}>
            Validate
          </ButtonPrimary>
        ) : (
          <ButtonPrimary
            onClick={() => {
              setAlertDialog(true);
            }}
          >
            Confirm
          </ButtonPrimary>
        )}
      </Box>
    </VStack>
  );
}

export function HrIdRender(data: any) {
  return (
    <div className=" whitespace-nowrap">
      <p className="py-1 text-left font-medium text-xs2 text-[#464E5F]">
        {data.data.hrId}
      </p>
    </div>
  );
}

export function EmployeeNameRender(data: any) {
  return (
    <div className=" whitespace-nowrap">
      <p className="py-1 text-left font-medium text-xs2 text-[#464E5F]">
        {data.data.employeeName}
      </p>
    </div>
  );
}

export function ResignationDateRender(data: any) {
  return (
    <div className=" whitespace-nowrap">
      <p className="py-1 text-left font-medium text-xs2 text-[#464E5F]">
        {formatDisplayDate(data.data.resignationDate)}
      </p>
    </div>
  );
}

export function LastworkingDateRender(data: any) {
  return (
    <div className=" whitespace-nowrap">
      <p className="py-1 text-left font-medium text-xs2 text-[#464E5F]">
        {formatDisplayDate(data.data.lastWorkingDate)}
      </p>
    </div>
  );
}

export function PauseVestingRender(data: any) {
  return (
    <div className=" whitespace-nowrap">
      <p className="py-1 text-left font-medium text-xs2 text-[#464E5F]">
        {data.data.pauseVesting}
      </p>
    </div>
  );
}

export function PauseVestingDateRender(data: any) {
  return (
    <div className=" whitespace-nowrap">
      <p className="py-1 text-left font-medium text-xs2 text-[#464E5F]">
        {formatDisplayDate(data.data.pauseVestingDate)}
      </p>
    </div>
  );
}

export function LapsedImmediatelyRender(data: any) {
  return (
    <div className=" whitespace-nowrap">
      <p className="py-1 text-left font-medium text-xs2 text-[#464E5F]">
        {data.data.lapsedImmediately}
      </p>
    </div>
  );
}

export function WarningsRender(data: any) {
  return (
    <div className="whitespace-nowrap">
      <ul className="py-1 text-left font-medium text-xs2 text-[#464E5F]">
        {data.data.warnings && data.data.warnings.length > 0 ? (
          data.data.warnings.map((warning: string, index: number) => (
            <li key={index}>{warning}</li>
          ))
        ) : (
          <li>-</li>
        )}
      </ul>
    </div>
  );
}

export function ErrorsRender(data: any) {
  return (
    <div className=" whitespace-nowrap">
      <p className="py-1 text-left font-medium text-xs2 text-[#464E5F]">
        {data.data.errors ?? "-"}
      </p>
    </div>
  );
}

export function NoteRender(data: any) {
  return (
    <div className=" whitespace-nowrap">
      <p className="py-1 text-left font-medium text-xs2 text-[#464E5F]">
        {data.data.note ?? "-"}
      </p>
    </div>
  );
}

export default BulkResignationSummary;
