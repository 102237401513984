export interface EmployeeExportExcelReq {
  heading: string;
  data: any;
  headers: any;
}

export interface Header {
  key: string;
  value: string;
}

export const employeeHeadingVal: string = "Employees";

export const employeeHeadersObj: any = {
  employeeIdentificationString: "Employee ID",
  employeeName: "Name",
  employeeDesignation: "Designation",
  department: "Department",
  email: "Email",
  isLoginProvided: "Credentials Generated",
  dateOfJoin: "Date of Joining",
  grade: "Grade",
  employmentStatus: "Status",
  loginAccess: "Login Access",
  noOfGrants: "No. of Grants",
  employeeLoginStatus: "Last login",
};
export const employeeHeaderslist: string[] = [
  "employeeIdentificationString",
  "employeeName",
  "employeeDesignation",
  "department",
  "email",
  "isLoginProvided",
  "dateOfJoin",
  "grade",
  "employmentStatus",
  "loginAccess",
  "noOfGrants",
  "employeeLoginStatus",
];

export interface EmployeeDataSync {
  id: string;
  companyId: string;
  masterDataType: string;
  createdAt: string;
  updatedAt: string;
  name?: string;
  createdBy: any;
  updatedBy: any;
  employeeDataSyncHistories: EmployeeDataSyncHistory[];
}

export interface EmployeeDataSyncHistory {
  id: string;
  companyId: string;
  syncType: string;
  syncStatus: string;
  note?: string;
  employeeDataSyncId: string;
  createdAt: string;
  updatedAt: string;
  createdBy: any;
  updatedBy: any;
}
