import { Icon } from "@iconify/react";
import Dialog from "@mui/material/Dialog";
import { GridApi } from "ag-grid-community";
import _ from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SortingComponent from "../../components/SortingVariation";
import { Action } from "../../components/shared/Dropdown";
import { EmptyTable } from "../../components/shared/EmptyTable";
import GenerateCredentials from "../../components/shared/GenerateCredentials";
import BasicMenu from "../../components/shared/Menu";
import Pagination from "../../components/shared/Pagination";
import Tooltip from "../../components/shared/Tooltip";
import {
  Box,
  Center,
  ExportTableData,
  HStack,
  TableDataExportProps,
  VStack,
  getCompanyName,
  isAdminViewer,
  isEsopViewer,
} from "../../components/utils";
import {
  useAddEmployeeExcel,
  useEmployeeTemplate,
  useEmployees,
  useExportToExcel,
} from "../../queries";
import { useEmployeeTableFilterStore } from "../../store/employeeTableFilters";
import { usePermissionStore } from "../../store/permissionStore";
import { Employee, EmployementStatus } from "../../types/Employee";
import {
  employeeHeadersObj,
  employeeHeaderslist,
  employeeHeadingVal,
} from "../../types/ExportExcelEmployee";
import {
  convertBase64ToBlob,
  downloadBlobObject,
  downloadS3File,
} from "../../utils/DownloadFile";
import { globalFilter, sort } from "../../utils/arrays";
import { determineUserAccessToResource } from "../../utils/auth";
import convertToBase64 from "../../utils/convertToBase64";
import { formatDisplayDate } from "../../utils/date";
import useIsMobile from "../../utils/detectDevice";
import {
  Action as DefaultAction,
  Resource,
} from "../../utils/interfaces/Companies";
import { _trimAll, limitString } from "../../utils/string";
import Avatar from "../esopOverview/Avatar";
import { BulkActions, ExportImport } from "../esopOverview/GrantsTable";
import EmployeesTableAgGrid from "./EmployeesTableAgGrid";
import EmployeeTableFilters from "./employeeTableFilters";
import GenericTableHeader from "../../shared/GenericTableHeader";
import EmployeeDetailsReportDialog from "./EmployeeDetailsReportDialog";

function EmployeesTable() {
  const navigate = useNavigate();
  const { isPlaceholderData, isFetching, data: _data } = useEmployees();
  const { permission } = usePermissionStore();
  const [exportData, setExportData] = useState<TableDataExportProps>({});
  // filter and sort logic
  const employeeTableRef = useRef<GridApi | null>(null);
  const [employeeImportDialog, setEmployeeImportDialog] = useState(false);
  const filter = useEmployeeTableFilterStore();
  const template = useEmployeeTemplate();
  const templateFile = template.data ?? "";
  const isUserAdminViewer = isAdminViewer();
  const isUserEsopViewer = isEsopViewer();

  const rowData = [];

  useEffect(() => {
    if (_data) setStakeHoldersCount(_data?.length);
  }, [_data]);

  const [stakeHoldersCount, setStakeHoldersCount] = useState<number>(
    _data?.length!
  );

  function handleTableFilterCount(data?: boolean) {
    const employeeCount = employeeTableRef?.current?.getModel().getRowCount();
    if (employeeCount !== undefined) {
      setStakeHoldersCount(employeeCount!);
      for (let i = 0; i < employeeCount!; i++) {
        const rowNode = employeeTableRef?.current?.getDisplayedRowAtIndex(i);
        rowData.push(rowNode?.data);
      }
    }
  }

  useEffect(() => {
    if (employeeTableRef.current) {
      const gridApi = employeeTableRef.current.getModel();
    }
  }, [employeeTableRef, _data]);

  useEffect(() => {
    handleTableFilterCount();
  }, [_data]);

  const [reportDialog, setReportDialog] = useState<{
    open: boolean;
    type?: string;
  }>({
    open: false,
    type: undefined,
  });

  const dataToConsume = useMemo(() => {
    if (!_data) return [];
    const filterResult = _data
      .filter(
        (employee) =>
          !filter.actionableInsightFilters[0].enabled ||
          employee.totalPercentageVested > 0.8
      )
      .filter(
        (employee) =>
          !filter.actionableInsightFilters[1].enabled ||
          !employee.totalOptionsGranted
      )
      .filter(
        (employee) =>
          !filter.actionableInsightFilters[2].enabled ||
          !employee.isLoginProvided
      )
      .filter(
        (employee) =>
          !filter.actionableInsightFilters[3].enabled || !employee.lastLogin
      )
      .filter(
        (employee) =>
          !filter.actionableInsightFilters[4].enabled ||
          (employee.employmentStatus &&
            employee.employmentStatus === EmployementStatus.RESIGNED)
      );

    return filterResult;
  }, [_data, filter.actionableInsightFilters, isPlaceholderData]);

  const employeeData = useMemo(
    () => dataToConsume || [],
    [isPlaceholderData, isFetching]
  );
  // selection logic
  const [selectedItems, setSelectedItems] = useState<Employee[]>([]);
  const onSelectionChange = (item: Employee | Employee[]) => {
    const employeeItem: Employee[] = [];

    if (_.isArray(item)) {
      const selectedEmployeeIds = item.map((emp) =>
        emp.employeeId?.toLocaleString()
      );

      const filteredRowIds: string[] = [];
      employeeTableRef.current?.forEachNodeAfterFilter((node: any) => {
        filteredRowIds.push(node?.data?.employeeId);
      });
      const filteredRows = _data?.filter((dataItem) =>
        filteredRowIds.includes(dataItem.employeeIdentificationString)
      );

      const selectedEmployees =
        filteredRows?.filter((emp) =>
          selectedEmployeeIds.includes(emp.employeeIdentificationString)
        ) || [];

      employeeItem.push(...selectedEmployees);
    } else {
      const selectedEmployeeId = item.employeeId?.toLocaleString();

      const filteredRowIds: string[] = [];
      employeeTableRef.current?.forEachNodeAfterFilter((node: any) => {
        filteredRowIds.push(node?.data?.employeeId);
      });
      const filteredRows = _data?.filter((dataItem) =>
        filteredRowIds.includes(dataItem.employeeIdentificationString)
      );

      const selectedEmployee = filteredRows?.find(
        (dataItem) =>
          dataItem.employeeIdentificationString === selectedEmployeeId
      );

      if (selectedEmployee) {
        employeeItem.push(selectedEmployee);
      }
    }

    setSelectedItems(() => [...employeeItem]);
  };
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  useEffect(() => {
    if (selectAllChecked) {
      setSelectedItems(_data || []);
    } else {
      setSelectedItems([]);
    }
  }, [_data, selectAllChecked]);
  const fileRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  //Export Import on Action
  const handleAction = (action: Action) => {
    if (action.disabled) return;
    if (action.name === "Export All To Excel") {
      exportClickHandler();
    } else if (action.name === "Export Selected") {
      const selectedRows = employeeTableRef.current
        ?.getSelectedRows()
        .map((row) => row?.employeeId);
      const rowsTobeExported = _data?.filter((dataItem) =>
        selectedRows?.includes(dataItem.employeeIdentificationString)
      );
      exportClickHandler(rowsTobeExported);
    } else if (action.name === "Export Filtered") {
      if (employeeTableRef.current?.isAnyFilterPresent()) {
        const filteredRowIds: string[] = [];
        employeeTableRef.current?.forEachNodeAfterFilter((node: any) => {
          filteredRowIds.push(node?.data?.employeeId);
        });
        const filteredRows = _data?.filter((dataItem) =>
          filteredRowIds.includes(dataItem.employeeIdentificationString)
        );
        exportClickHandler(filteredRows);
      }
    } else if (action.name === "Download Template") {
      downloadS3File(templateFile);
    } else if (action.name === "Import Employees") {
      navigate(`/options/onboarding/employees`);
    } else if (action.name === "Employee Details Report") {
      setReportDialog({ open: true, type: "Financial Report" });
    } else if (action.name === "Employee Data sources") {
      navigate(`/options/employeeSyncs`);
    } else if (action.name === "Upload Employee Tax") {
      navigate(`/options/employeetax`);
    }
  };

  const { mutate: exportExcel } = useExportToExcel();

  const fileName = `Option Holders Details_${getCompanyName()}`;
  const exportClickHandler = (dataToBeExported = _data) => {
    const reqData = dataToBeExported?.map((item: any) => {
      if (item.lastLogin) {
        item.lastLogin = formatDisplayDate(
          new Date(item.lastLogin).toUTCString()
        );
      }
      item.dateOfJoin = formatDisplayDate(
        new Date(item.dateOfJoin).toUTCString()
      );
      item.isLoginProvided =
        item.isLoginProvided == null ? false : item.isLoginProvided;
      item.loginAccess = item.isDeactivated
        ? "Deactivated"
        : item.isLoginProvided
        ? "Provided"
        : "Not Provided";
      item.employeeLoginStatus = item.lastLogin;
      return item;
    });

    setExportData({
      data: reqData,
      headers: getHeaderObj(employeeHeaderslist),
      heading: employeeHeadingVal,
      open: true,
      fileName,
      tableName: "Employee",
    });
  };
  const getHeaderObj = (keys: string[]) =>
    keys.map((ele: string) => ({
      key: ele,
      value: employeeHeadersObj[ele],
    }));
  const { mutate: addEmployeeExcel } = useAddEmployeeExcel();

  function handleChange(e: any) {
    const file = e.target.files[0];
    const allowedFileExtensions = ["xlsx", "xls", "ods"];
    if (
      allowedFileExtensions.some((extension) => file.name.endsWith(extension))
    ) {
      convertToBase64(file).then((data) => {
        const base64 = {
          file: data as string,
        };
        addEmployeeExcel(base64, {
          onSuccess: (data) => {
            toast(
              `${data.length} Employee details were successfully uploaded`,
              {
                type: "success",
              }
            );
          },
          onError: (error: { response: { data: { reason: string } } }) => {
            toast(error.response.data.reason, {
              type: "error",
              autoClose: 5000,
            });
          },
        });
      });
    } else {
      toast(
        ` Invalid file type, allowed file types are ${allowedFileExtensions.join(
          ", "
        )}`,
        { type: "error" }
      );
    }
    fileRef.current.value = "";
  }
  //pagination logic

  const [bulkActionDialog, setBulkActionDialog] = useState<{
    open: boolean;
    employeeItem?: Employee[];
    empWithCred?: Employee[];
    empWithoutCred?: Employee[];
    mode?: "Generate Credentials";
  }>({
    open: false,
    employeeItem: [],
    empWithCred: [],
    empWithoutCred: [],
    mode: "Generate Credentials",
  });
  function handleBulkAction(action: Action, employeeItem: Employee[]): void {
    if (action.disabled) {
      return;
    }
    if (action.name === "Generate Credentials") {
      const empWithCredentials = employeeItem.filter(
        (employee) => employee.isLoginProvided === true
      );
      const empWithoutCredentials = employeeItem.filter(
        (employee) => employee.isLoginProvided !== true
      );
      setBulkActionDialog({
        open: true,
        employeeItem,
        empWithCred: empWithCredentials,
        empWithoutCred: empWithoutCredentials,
        mode: "Generate Credentials",
      });
    }
    if (action.name === "Upload Resignation") {
      navigate(`/options/bulkResignation`);
    }
  }
  function viewDetails(emp: Employee) {
    navigate(`/options/employeeDetails/${emp.id}`);
  }
  const { isMobile } = useIsMobile();
  const [showFilter, setShowFilter] = useState(false);
  function showAdvanceFilters() {
    setShowFilter(!showFilter);
  }
  return (
    <Box
      className={` p-4 bg-white rounded-lg  ${
        isMobile ? "min-w-[1040px]" : "w-full"
      }`}
    >
      <HStack aria-label="toolbar" className="justify-between">
        <GenericTableHeader
          heading={"Stakeholders"}
          subHeading={""}
          additionalInfo={<>{stakeHoldersCount} Stakeholders</>}
        ></GenericTableHeader>
        <HStack aria-label="toolbar" className="justify-between mb-2">
          <HStack className="h-8 min-w-min">
            {isMobile && (
              <Center className={`p-2 px-3 mx-2 text-white rounded bg-white`}>
                <Icon
                  icon={`${
                    showFilter ? "material-symbols:close-rounded" : "el:filter"
                  }`}
                  className={` text-orange-501 rounded`}
                  height={20}
                  onClick={() => showAdvanceFilters()}
                />
              </Center>
            )}
            <Center>
              <BulkActions
                actions={[
                  {
                    name: "Generate Credentials",
                    disabled:
                      selectedItems.length === 0 ||
                      !determineUserAccessToResource(
                        permission?.aclList || [],
                        Resource.Employee,
                        DefaultAction.Create
                      ),
                  }
                ]}
                onAction={(action) => handleBulkAction(action, selectedItems)}
              />
            </Center>
            <Dialog
              open={bulkActionDialog.open}
              onClose={() =>
                setBulkActionDialog({
                  open: false,
                  employeeItem: [],
                })
              }
            >
              <GenerateCredentials
                open={bulkActionDialog.open}
                employeeList={selectedItems}
                empWithCred={bulkActionDialog.empWithCred || []}
                empWithoutCred={bulkActionDialog.empWithoutCred || []}
                onClose={() =>
                  setBulkActionDialog({
                    open: false,
                    employeeItem: bulkActionDialog.employeeItem,
                  })
                }
                onSecondaryAction={() =>
                  setBulkActionDialog({
                    open: false,
                    employeeItem: bulkActionDialog.employeeItem,
                  })
                }
              />
            </Dialog>
            {reportDialog.type === "Financial Report" && (
              <Dialog
                open={reportDialog.open}
                onClose={() => setReportDialog({ open: false })}
                maxWidth="lg"
              >
                <div className="w-[500px] min-h-min mx-auto bg-white rounded-lg">
                  <EmployeeDetailsReportDialog
                    onClose={() => setReportDialog({ open: false })}
                  />
                </div>
              </Dialog>
            )}
            <Dialog open={exportData?.open || false} maxWidth="md">
              <ExportTableData
                onDiscard={() => setExportData({ ...exportData, open: false })}
                data={exportData?.data || []}
                fileName={exportData?.fileName || ""}
                headers={exportData?.headers || []}
                heading={exportData?.heading || ""}
                tableName={exportData?.tableName || ""}
              />
            </Dialog>
            <input
              ref={fileRef}
              onChange={handleChange}
              multiple={false}
              type="file"
              accept=".xlsx, .xls, .ods"
              hidden
            />
            <ExportImport
              actions={[
                {
                  name: "Export All To Excel",
                },
                {
                  name: "Export Selected",
                  disabled:
                    employeeTableRef.current?.getSelectedRows()?.length === 0,
                },
                {
                  name: "Export Filtered",
                  disabled: !employeeTableRef.current?.isAnyFilterPresent(),
                },
                {
                  name: "Import Employees",
                  disabled: isUserAdminViewer || isUserEsopViewer || false,
                },
                {
                  name: "Download Template",
                },
                {
                  name: "Employee Data sources",
                  disabled: isUserAdminViewer || isUserEsopViewer || false,
                },
                {
                  name: "Employee Details Report",
                },
                {
                  name: "Upload Employee Tax",
                },
              ]}
              onAction={(action) => handleAction(action)}
            />
          </HStack>
          {isMobile && showFilter && (
            <Box
              className={`z-20  overflow-y-auto bg-white flex mt-8 justify-center absolute transition delay-300 border border-solid ${
                showFilter ? "0" : "-100%"
              }`}
            >
              <EmployeeTableFilters />
            </Box>
          )}
        </HStack>
      </HStack>
      <HStack className="justify-between w-full ">
        <EmployeesTableAgGrid
          key={"EmployyeTable"}
          employeeTableData={employeeData}
          onSelectedRows={onSelectionChange}
          handleAction={handleBulkAction}
          gridRef={employeeTableRef}
          tableFilterCount={handleTableFilterCount}
        />
      </HStack>
    </Box>
  );
}

export default EmployeesTable;
