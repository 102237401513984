import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  getCompanyName,
  HStack,
  VStack,
} from "../../../components/utils";
import { Input, Label } from "../../../components/shared/InputField";
import { Select } from "../../../components/shared/Select";
import { MultiSelectV2 } from "../../../components/shared/MultiSelectV2";
import { ReportType } from "../utils/report";
import { OptionTypes } from "../../../components/shared/MultiSelect";
import { useEsopPlans } from "../../../queries";
import { PlanState } from "../../../types/EsopPlan";
import { generateGrantReport } from "../../../api/Esop";
import { ReportDto } from "../../../types/Grant";
import {
  convertBase64ToBlob,
  downloadBlobObject,
} from "../../../utils/DownloadFile";
import { useGetOptionBuyback } from "../../../queries/optionBuyback";
import { EventType } from "../../../types/OptionBuyback";

const PoolStatementDialog = ({
  onClose = () => {},
  data,
}: Readonly<{
  onClose: () => void;
  data: any;
}>) => {
  const { data: _plans } = useEsopPlans();
  const plans = _plans || [];
  const planOptions: OptionTypes[] = [];
  const ids: string[] = [];
  const [shouldPiiBeMasked, setShouldPiiBeMasked] = useState(false);

  plans.forEach((plan) => {
    planOptions.push({ value: plan.planName });
    ids.push(plan.esopPlanId);
  });

  const initialValues: ReportType = {
    planIds: [],
    startDate: undefined,
    endDate: undefined,
  };
  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      values.maskPiiData = shouldPiiBeMasked;
      data
        ?.onSubmitFunction(values)
        .then((res: any) => {
          downloadReport(res.data);
        })
        .catch((err: any) => {
          toast(
            err?.response?.data?.errors || "Error while downloading the report",
            { type: "error", autoClose: 2000 }
          );
        });
    },
  });
  const fileName = `Pool Statement_${getCompanyName()}.xlsx`;
  async function downloadReport(reportDto: ReportDto) {
    onClose();
    const blob = await convertBase64ToBlob(
      reportDto.base64File,
      reportDto.fileType
    );
    downloadBlobObject(blob, fileName);
  }

  return (
    <div className="">
      <div className="p-8 text-lg font-medium border-b">
        <h6 className="flex justify-between">
          {data?.headerName}
          <span
            className="cursor-pointer"
            onClick={() => {
              onClose();
            }}
          >
            X
          </span>
        </h6>
      </div>
      <VStack className="w-full gap-2 px-8 py-4 ">
        <HStack className="gap-8 ">
          <div className="flex-1">
            <Label className={"text-sm font-normal"}>Plan Names</Label>

            <MultiSelectV2
              _className="w-full"
              multiple={true}
              placeholder={"--Select plans--"}
              options={planOptions}
              handleSelection={(e) => {
                const planIds = plans
                  .filter((p) => e.includes(p.planName))
                  .map((p) => p.esopPlanId);
                formik.setFieldValue("planIds", planIds);
              }}
              optionValues={plans
                .filter((p) => formik.values.planIds?.includes(p.esopPlanId))
                .map((p) => p.planName)}
            />
          </div>
        </HStack>
        <HStack className="justify-end gap-2 mt-7">
          <input
            type="checkbox"
            checked={shouldPiiBeMasked}
            onChange={() => setShouldPiiBeMasked(!shouldPiiBeMasked)}
            className="text-white accent-orange-501"
            name=""
            id=""
          />
          <span>Hide all Personally Identifiable Information</span>
        </HStack>
        <HStack className="justify-end ">
          <ButtonPrimary
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            Generate
          </ButtonPrimary>
        </HStack>
      </VStack>
    </div>
  );
};

export default PoolStatementDialog;
