import {
  format,
  formatDistanceToNow,
  addDays,
  subDays,
  startOfMonth,
  endOfMonth,
} from "date-fns";

export function formatTimeZoneSetting(date: any) {
  const mydate = new Date(date);
  mydate.setFullYear(mydate.getFullYear());
  mydate.setMonth(mydate.getMonth());
  mydate.setDate(mydate.getDate());

  const timezone = 5.5;
  const offset = (mydate.getTimezoneOffset() + timezone * 60) * 60 * 1000;

  mydate.setTime(mydate.getTime() + offset);
  return mydate;
}

export function formatWithTimeZone(dateStr: string) {
  return format(new Date(dateStr), "yyyy-MM-dd'T'HH:mm:ss.SSS'+0000'");
}

export function formatDate(dateStr: string | Date | undefined) {
  const dateFormat = "yyyy-MM-dd";
  if (!dateStr) {
    dateStr = new Date();
  }
  if (typeof dateStr === "string") {
    return format(new Date(dateStr), dateFormat);
  } else {
    return format(dateStr, dateFormat);
  }
}

export function formatDisplayDate(dateStr: string | Date | undefined) {
  if (
    !dateStr ||
    dateStr === "undefined date" ||
    dateStr === "null" ||
    dateStr === "" ||
    dateStr === "undefined" ||
    new Date(dateStr).toString() === "Invalid Date"
  ) {
    return "Invalid Date";
  }
  const dateFormat = "dd MMM yyyy";
  if (!dateStr) {
    dateStr = new Date();
  }
  if (typeof dateStr === "string") {
    return format(new Date(dateStr), dateFormat);
  } else {
    return format(dateStr, dateFormat);
  }
}

export function modifyDate(dateVal: string, daysToBeModified: number) {
  const date = new Date(dateVal);
  date.setDate(date.getDate() + daysToBeModified);
  return date;
}

export function calculateDateDiffTillNow(dateValue = new Date()) {
  const today = new Date();
  if (
    dateValue.getDay() === today.getDay() &&
    dateValue.getMonth() === today.getMonth() &&
    dateValue.getFullYear() === today.getFullYear()
  ) {
    return "today";
  }
  return formatDistanceToNow(dateValue, { addSuffix: true });
}

export function extractDateFromTimeStamp(inputDate?: string) {
  if (inputDate === undefined) {
    inputDate = "1900-01-02";
  }
  if (inputDate.includes("T")) {
    inputDate = inputDate.split("T")[0];
  }
  const tempDate = new Date(inputDate);
  tempDate.setDate(tempDate.getDate() - 1);
  inputDate = tempDate.toLocaleDateString();
  return inputDate;
}
function dateComparator(date1: string, date2: string) {
  const date1Number = monthToComparableNumber(date1);
  const date2Number = monthToComparableNumber(date2);
  if (date1Number === null && date2Number === null) {
    return 0;
  }
  if (date1Number === null) {
    return -1;
  }
  if (date2Number === null) {
    return 1;
  }
  return date1Number - date2Number;
}
function monthToComparableNumber(date: string) {
  if (date === undefined || date === null || date.length !== 10) {
    return null;
  }
  const yearNumber = Number.parseInt(date.substring(6, 10), 10);
  const monthNumber = Number.parseInt(date.substring(3, 5), 10);
  const dayNumber = Number.parseInt(date.substring(0, 2), 10);
  return yearNumber * 10000 + monthNumber * 100 + dayNumber;
}

export function getActualVestingDay(
  vestingDate: Date,
  actualVestingDay: string
) {
  if (actualVestingDay === "SAME_DAY") {
    return vestingDate;
  }
  if (actualVestingDay === "NEXT_DAY") {
    return addDays(vestingDate, 1);
  }
  if (actualVestingDay === "PREVIOUS_DAY") {
    return subDays(vestingDate, 1);
  }
  if (actualVestingDay === "STARTING_OF_MONTH") {
    return startOfMonth(vestingDate);
  }
  if (actualVestingDay === "END_OF_MONTH") {
    return endOfMonth(vestingDate);
  }
  return vestingDate;
}
