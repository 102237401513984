import React, { useEffect, useMemo, useRef, useState } from "react";
import { Input } from "postcss";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import { LinearProgress } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import {
  CellClickedEvent,
  ColDef,
  GridApi,
  SideBarDef,
} from "ag-grid-community";

import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  HStack,
  VStack,
} from "../../components/utils";
import convertToBase64 from "../../utils/convertToBase64";

import { getGrantTemplate } from "../../api/Esop";
import {
  convertBase64ToBlob,
  downloadBlobObject,
  downloadS3File,
} from "../../utils/DownloadFile";
import Tooltip from "../../components/shared/Tooltip";
import { Label } from "../../components/shared/InputField";
import { useAddBulkResignationExcel } from "../../queries/esopPlan";
import { BulkResignationDto } from "../../types/Employee";
import BulkResignationSummary from "./BulkResignationSummary";

function BulkResignation() {
  const [showStatus, setShowStatus] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [errorData, setErrorData] = useState<string>();

  const [bulkResignationData, setBulkResignationData] = useState<
    BulkResignationDto[]
  >([]);

  const fileRef = useRef<HTMLInputElement | null>(null);

  const { mutate: addBulkResignationExcel, error: addBulkResignationError } =
    useAddBulkResignationExcel();

  function handleChange(e: any) {
    const file = e.target?.files[0];
    if (!file) return;
    const allowedFileExtensions = ["xlsx", "xls", "ods"];
    if (
      allowedFileExtensions.some((extension) => file?.name.endsWith(extension))
    ) {
      setShowStatus(true);
      const formData = new FormData();
      formData.append("file", file);

      addBulkResignationExcel(formData, {
        onSuccess: (data) => {
          setBulkResignationData(data);
          setShowStatus(false);
          setShowSummary(true);
          setErrorData("");

          toast(data.message, { type: "success" });
        },
        onError: (error) => {
          setShowStatus(false);
          setShowSummary(false);

          if (fileRef.current) fileRef.current.value = "";
          const errorData = error.response.data.errorMessage;
          setErrorData(errorData);
        },
      });
    } else {
      toast(
        ` Invalid file type, allowed file types are ${allowedFileExtensions.join(
          ", "
        )}`,
        { type: "error" }
      );
      setShowStatus(false);
      e.target.value = "";
    }
  }
  const gridApi = useRef<GridApi | any>(null);

  const onGridReady = (params: any) => {
    gridApi.current = params;
  };

  const downloadTemplate = () => {
    downloadS3File(
      "https://equion-dev.s3.us-west-2.amazonaws.com/assets/Esop_Bulk_resignation_upload_template.xlsx"
    );
  };

  return (
    <VStack className="justify-between w-full h-full px-4 bg-white border-2 rounded-md min-h-[600px]">
      <VStack>
        <HStack className="py-4 text-lg font-medium text-left bg-white border-b">
          <h6 className="flex-1">
            {showSummary ? "Resignation Summary" : "Upload Resignation"}
          </h6>
          <ButtonPrimary onClick={downloadTemplate}>
            Download Template
          </ButtonPrimary>
        </HStack>
        {!showSummary && (
          <VStack className="w-full h-full gap-6 px-2 py-4 bg-white">
            {showStatus && (
              <VStack className="justify-between w-full h-full gap-6 px-2 py-4 my-auto bg-white">
                <LinearProgress className="w-full" variant="indeterminate" />
                <HStack className="justify-between w-full ">
                  <p className="text-sm font-normal text-center">
                    Please wait while we validate the data
                  </p>
                </HStack>
              </VStack>
            )}
            {!showStatus && (
              <>
                <Box className="flex justify-center w-full px-10 text-lg font-medium">
                  <h6 className="flex justify-between">
                    Upload your Resignation Excel{" "}
                    <Tooltip
                      text={`Upload your excel sheet containing bulk resignation data`}
                      _className="w-[400px]"
                    >
                      <Icon
                        icon="material-symbols:info-outline-rounded"
                        height={24}
                        width={24}
                        className="w-full ml-2 text-xs font-medium rounded cursor-pointer text-slate-dark"
                      />
                    </Tooltip>
                  </h6>
                </Box>
                <HStack className="gap-8 pt-8 mx-auto">
                  <div className="flex-1">
                    <Label className="text-sm font-normal">Excel File</Label>
                    <input
                      ref={fileRef}
                      className="w-full form-input"
                      type="file"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={handleChange}
                    />
                    <span className="py-2 text-xs italic text-gray-500">
                      You can download the template for uploading bulk
                      resignation{" "}
                      <a
                        className="text-blue-500 underline cursor-pointer"
                        onClick={downloadTemplate}
                      >
                        {" "}
                        here
                      </a>
                    </span>
                  </div>
                </HStack>
              </>
            )}
          </VStack>
        )}

        {showSummary && (
          <BulkResignationSummary
            summaryData={bulkResignationData}
            onDiscard={() => setShowSummary(false)}
          />
        )}
        {errorData && (
          <Box className="flex flex-col justify-center w-full px-10 text-lg font-medium py-9">
            <div className="flex flex-col w-full py-2 border border-gray-200 rounded">
              <div className="flex items-start px-4 pb-4">
                <div className="pt-1">
                  <svg
                    className="w-5 h-5 mr-4 text-red-500 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                </div>
                <div className="flex-1">
                  <p className="font-bold">Upload Failed</p>
                  <p className="text-xs text-red-400">{errorData}</p>
                </div>
              </div>
            </div>
          </Box>
        )}
      </VStack>
    </VStack>
  );
}

export default BulkResignation;
