/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { FileUploader } from "react-drag-drop-files";
import html2canvas from "html2canvas";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import {
  Box,
  ButtonPrimary,
  ButtonSecondary,
  HStack,
  VStack,
} from "../components/utils";
import convertToBase64 from "../utils/convertToBase64";
import { Input, InputWithLabel } from "../components/shared/InputField";
import { useCompanyStore } from "../store";
import { useSignaturePadDialog } from "../store/useDialogStore";

export type SignatureProps = {
  onClose: () => void;
  ids?: string[];
  type?: string;
};

const SignatureGateway = (props: SignatureProps) => {
  const { onClose, ids, type } = props;
  const [step, setStep] = useState(2);
  const { companyData } = useCompanyStore();
  const { state: showSignaturePad, setState: setShowSignaturePad } =
    useSignaturePadDialog();
  const grantLetterSettings = companyData?.companySettings.grantLetterSettings;
  function getSignature() {
    setShowSignaturePad({ open: true, signatureType: "esign" });
  }
  return (
    <>
      <HStack className="justify-end p-4 px-4 mt-3 text-lg font-medium border-b stone-700">
        <span className="cursor-pointer" onClick={onClose}>
          X
        </span>
      </HStack>
      {step === 0 && (
        <HStack className="h-full m-4 border border-collapse border-solid justify-evenly">
          <HStack className="gap-4 p-4 border hover:border-orange-501">
            <VStack className="items-center justify-between gap-4">
              <VStack>
                <li className="list-none">
                  Inscribing the signature can be done via 3 Modes:-
                </li>
                <li className="list-none">
                  Drag and Drop any signature Image.
                </li>
                <li className="list-none">
                  Draw your signature on Embedded Signature pad.
                </li>
                <li className="list-none">
                  Generate Signature by entering your name.
                </li>
              </VStack>
              <VStack>
                <ButtonPrimary onClick={() => setStep(2)} className="w-fit">
                  Inscribe Signature
                </ButtonPrimary>
              </VStack>
            </VStack>
          </HStack>
        </HStack>
      )}
      {step === 2 && <SignaturePad />}
    </>
  );
};
const SignaturePad = () => {
  const { state: showSignaturePad, setState: setShowSignaturePad } =
    useSignaturePadDialog();
  const [mode, setMode] = useState("");
  const canvasRef = useRef<SignatureCanvas>(null);
  const [uploadedImage, setUploadedImage] = useState("");
  const [generatedSignature, setGeneratedSignature] = useState("");
  function getUploadedImage(signature: string) {
    setUploadedImage(signature);
    setShowSignaturePad({ ...showSignaturePad, signature });
  }
  return (
    <Box className="h-100">
      {mode === "" && (
        <>
          <HStack className="h-full mt-4 justify-evenly">
            <VStack>
              <Box className="w-full h-60">
                <Box
                  onClick={() => setMode("draw")}
                  className="w-56 h-56 p-4 text-center align-middle cursor-pointer hover:border-orange-501 hover:border-4 hover:border-solid text-slate-501 justify-middle"
                >
                  <div className="p-4 text-base underline">Draw Signature</div>
                  <div className="flex justify-center">
                    <Icon
                      className="cursor-pointer text-orange-501"
                      icon="material-symbols:draw"
                      width={36}
                    />
                  </div>
                  <div className="mt-2">
                    Go artistic. Use your mouse or trackpad to make a statement.
                  </div>
                </Box>
              </Box>
            </VStack>
            <VStack>
              <Box className="w-full h-60">
                <Box
                  onClick={() => setMode("type")}
                  className="w-56 h-56 p-4 text-center align-middle cursor-pointer hover:border-orange-501 hover:border-4 hover:border-solid text-slate-501 justify-middle"
                >
                  <div className="p-4 text-base underline">Type Signature</div>
                  <div className="flex justify-center">
                    <Icon
                      className="cursor-pointer text-orange-501"
                      icon="tabler:keyboard"
                      width={36}
                    />
                  </div>
                  <div>
                    Type your name and choose the font that best matches your
                    personality.
                  </div>
                </Box>
              </Box>
            </VStack>
          </HStack>
        </>
      )}
      {mode === "draw" && (
        <HStack className="gap-2 px-4 ">
          <VStack>
            <HStack className="justify-center pt-10">
              Draw your Signature Here
            </HStack>
            <HStack className="border-2 border-solid bg-slate-300">
              <SignatureCanvas
                penColor="black"
                ref={canvasRef}
                canvasProps={{
                  width: "300px",
                  height: "200px",
                  className: "bg-slate-300",
                }}
                backgroundColor="rgb(255, 255, 255)"
              />
            </HStack>
          </VStack>
          <VStack className="">
            <HStack className="justify-center pt-10">
              Upload your Signature Here
            </HStack>
            <HStack className="mt-6">
              <DragDrop onImageUpload={getUploadedImage} />
            </HStack>
            {uploadedImage && (
              <HStack className="items-center px-4 mx-auto mt-6 border border-solid rounded-md">
                <img
                  src={`data:image/png;base64,${uploadedImage}`}
                  alt=""
                  id="signatureImage"
                />
              </HStack>
            )}
          </VStack>
        </HStack>
      )}
      {mode === "type" && (
        <HStack className="w-full px-4">
          <SignatureGenerator selectedSignaure={setGeneratedSignature} />
        </HStack>
      )}
      {mode !== "" && (
        <HStack className="justify-end">
          <ButtonPrimary
            className="m-2"
            onClick={() => {
              if (mode === "draw") {
                if (canvasRef?.current?.isEmpty() && uploadedImage === "") {
                  setShowSignaturePad({
                    open: true,
                    signature: "",
                    signatureType: "error",
                  });
                  toast("Draw/upload your signature", {
                    autoClose: 2000,
                    type: "warning",
                  });
                }
                if (uploadedImage !== "") {
                  setShowSignaturePad({
                    ...showSignaturePad,
                    signatureType: "regular",
                  });
                }
                if (!canvasRef?.current?.isEmpty()) {
                  setShowSignaturePad({
                    ...showSignaturePad,
                    signature:
                      canvasRef?.current?.getTrimmedCanvas().toDataURL() || "",
                    signatureType: "regular",
                  });
                }
              } else if (generatedSignature !== "") {
                setShowSignaturePad({
                  ...showSignaturePad,
                  signature: generatedSignature,
                  signatureType: "regular",
                });
              }
            }}
          >
            {!showSignaturePad.isLoading ? (
              `Save`
            ) : (
              <>
                Please Wait...
                <Icon
                  className="animate-spin"
                  icon="lucide:loader-2"
                  width={18}
                />
              </>
            )}
          </ButtonPrimary>
          {mode !== "type" && (
            <ButtonSecondary
              className="m-2"
              onClick={() => canvasRef?.current?.clear()}
            >
              Clear
            </ButtonSecondary>
          )}
        </HStack>
      )}
    </Box>
  );
};

function DragDrop(props: { onImageUpload: (sign: string) => void }) {
  const { onImageUpload } = props;
  const [file, setFile] = useState("");
  const fileTypes = ["JPG", "JPEG", "PNG"];
  const isValidImageFileUploaded = (file: File) => {
    const validExtensions = ["png", "jpeg", "jpg"];
    const fileExtension = file.type.split("/")[1];
    if (!validExtensions.includes(fileExtension)) {
      return false;
    }
    if (file.size / 1024 > 50) {
      toast(
        "File size should be less than 50kb, please upload a different file",
        {
          type: "error",
        }
      );
      return false;
    }
    return true;
  };
  const handleChange = async (file: any) => {
    if (file && file.length > 0) {
      const fileToCheck = file[0];
      if (!isValidImageFileUploaded(fileToCheck)) {
        return;
      }
      const img = new Image();
      img.src = URL.createObjectURL(fileToCheck);
      img.onload = async () => {
        const width = img.width;
        const height = img.height;
        if (width > 240 || height > 120) {
          toast(
            "File height and width should be less than 120px and 240px, please upload a different file",
            {
              type: "error",
            }
          );
        } else {
          convertToBase64(file[0]).then((data: any) => {
            setFile(data as string);
            onImageUpload(data as unknown as string);
          });
        }
      };
    }
  };
  return (
    <FileUploader
      handleChange={handleChange}
      multiple="false"
      name="file"
      types={fileTypes}
    />
  );
}

const SignatureGenerator = (props: { selectedSignaure: any }) => {
  const [name, setName] = useState("");
  const [signatures, setSignatures] = useState([]);
  const [selectedSignature, setSelectedSignature] = useState("");
  const { state: showSignaturePad, setState: setShowSignaturePad } =
    useSignaturePadDialog();

  const onSignatureSelect = (signtaure: string) => {
    setSelectedSignature(signtaure);
    setShowSignaturePad({ open: true, signature: signtaure || "" });
    props.selectedSignaure(signtaure);
  };
  const generateSignatureStyles = () => {
    const generatedSignatures = [];

    for (let i = 0; i < 10; i++) {
      const style = {
        fontFamily: [
          "cursive",
          "Great Vibes",
          "Nothing You Could Do",
          "serif",
          "Marck Script",
        ][Math.floor(Math.random() * 5)],
        color: ["black", "blue"][Math.floor(Math.random() * 2)],
        textTransform: ["uppercase", "lowercase", "capitalize"][
          Math.floor(Math.random() * 3)
        ],
        fontSize: "18px",
      };
      generatedSignatures.push({ name, style });
    }

    setSignatures(generatedSignatures as []);
  };

  return (
    <HStack className="w-full min-w-[600px] h-64 mt-8 mb-4 justify-evenly">
      <VStack>
        <HStack>
          <Input
            type="text"
            placeholder="Enter Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </HStack>
        <HStack className="mt-4">
          <ButtonPrimary onClick={generateSignatureStyles}>
            {signatures.length > 0 ? "Generate More" : "Generate Signatures"}
          </ButtonPrimary>
        </HStack>
        {selectedSignature && (
          <HStack className="mt-8">
            <table>
              <tbody>
                <tr className="">Selected Signature</tr>
                <tr>
                  {selectedSignature && (
                    <img className="max-w-xs mt-4" src={selectedSignature} />
                  )}
                </tr>
              </tbody>
            </table>
          </HStack>
        )}
      </VStack>
      <VStack className="w-1/2 overflow-y-auto">
        <table>
          <thead>
            <tr>
              <td>Generated Signatures:</td>
            </tr>
          </thead>
          <tbody>
            {signatures.length > 0 &&
              signatures.map((signature: { style: {}; name: any }, index) => (
                <tr key={index} style={signature?.style}>
                  <td key={index} style={signature?.style}>
                    <TextToImage
                      onSignatureSelect={onSignatureSelect}
                      style={signature.style}
                      text={signature.name}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </VStack>
    </HStack>
  );
};
const TextToImage = (props: {
  text: string;
  style: any;
  onSignatureSelect: any;
}) => {
  const { text, style, onSignatureSelect } = props;
  const imageRef = useRef<any>();

  const convertToImage = () => {
    imageRef.current.style.backgroundColor = "transparent";
    html2canvas(imageRef.current as HTMLElement).then((canvas) => {
      // Convert the canvas to a data URL
      const image = canvas.toDataURL();
      onSignatureSelect(image);
    });
  };

  return (
    <div
      onClick={convertToImage}
      className="p-4 m-4 cursor-pointer min-h-fit"
      ref={imageRef}
      style={style}
    >
      {text}
    </div>
  );
};

export default SignatureGateway;
