import {
  AddPlanReq,
  ForfeitureType,
  StrikePriceType,
} from "../../types/EsopPlan";

export const planInitValues: AddPlanReq = {
  id: undefined,
  planName: "",
  planType: "ESOP",
  planStartDate: "",
  poolSize: 0,
  planTerm: 0,
  planDescription: "",
  notes: "",
  vestingTemplateId: "",
  vestingTemplateName: "",
  isFractional: false,
  conversionNumber: 1,
  conversionShares: 1,
  poolShares: 0,
  isManagedByTrust: false,
  trustId: "",
  trustName: "",
  sharesManagedByTrust: 0,
  forfeitureConditions: [{ extendedMonths: 0 }],
  unitName: "options",
  unitType: "ESOP",
  forfeitureType: ForfeitureType.NEVER,
  strikePriceType: StrikePriceType.OPTIONS_PRICE,
};
