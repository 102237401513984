import React from "react";
import { Icon } from "@iconify/react";
import Tooltip from "./Tooltip";

type SwitchButtonType = {
  className?: string;
  value: boolean;
  label: string;
  leftLabel?: string;
  disabled?: boolean;
  infoText?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export function SwitchButton(props: SwitchButtonType): React.ReactElement {
  const { label, className, value, leftLabel, onClick, disabled, infoText } =
    props;
  const toggleClass = " transform translate-x-full";
  const _className = className;
  return (
    <>
      {leftLabel && <label className={`${_className}`}>{leftLabel}</label>}
      <div
        className={`md:w-12 md:h-6 w-12 h-6 flex items-center p-1 rounded-full cursor-pointer ${
          value ? "bg-orange-500" : "bg-slate-300"
        }  ${_className}`}
        onClick={disabled ? undefined : onClick}
      >
        <div
          className={`bg-white md:w-5 md:h-5 h-5 w-5 rounded-full shadow-md transform duration-500 ease-in-out ${
            value ? toggleClass : null
          } ${disabled ? "opacity-50 cursor-not-allowed" : ""}`}
        ></div>
      </div>
      {infoText && (
        <Tooltip text={infoText} _className="w-[400px]">
          <div className="pt-4">
            <Icon
              icon="material-symbols:info-outline-rounded"
              height={24}
              width={24}
              className="w-full text-xs font-medium rounded cursor-pointer text-slate-dark"
            />
          </div>
        </Tooltip>
      )}
      <label className={` ${infoText ? "mt-4 ml-4 mr-0" : _className}`}>
        {label}
      </label>
    </>
  );
}
