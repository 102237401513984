/*eslint arrow-body-style: [0]*/
import { Icon } from "@iconify/react";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  Title,
  Tooltip,
  TooltipItem,
} from "chart.js";
import { format } from "date-fns";
import * as _ from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useParams } from "react-router";
import { AgGridReact } from "ag-grid-react";
import { ColDef, GridApi } from "ag-grid-community";

import { Box, HStack, VStack } from "../../components/utils";
import { useVestingTables } from "../../queries";
import { EmployementStatus } from "../../types/Employee";
import { getCurrencyType } from "../../utils/currencyFormatter";
import { formatDisplayDate } from "../../utils/date";
import { getFormattedValue } from "../../utils/string";
import {
  EmployeeGrantTransactionDetail,
  EmployeeVestingDetail,
} from "../../types/VestingTemplate";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const VestingSchedule = () => {
  const { id } = useParams();
  const _id = id || "";
  const { data: _vestingSchedule, refetch } = useVestingTables(_id);
  useEffect(() => {
    if (!_.isEmpty(_id)) {
      refetch();
    }
  }, [_id]);
  const [showTable, setShowTable] = useState(false);
  const vestingScheduleTemplate = _vestingSchedule || [];
  const currency = getCurrencyType();
  const isFractional = vestingScheduleTemplate.some(
    (schedule) => schedule.isFractional
  );
  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItems: TooltipItem<"bar">) => {
            const label = `${tooltipItems.dataset.label || ""}: ${
              isFractional
                ? (tooltipItems.raw as string)
                : tooltipItems.formattedValue
            }`;
            return label;
          },
        },
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Vesting Projections",
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          text: "Year",
        },
        stacked: true,
        grid: {
          borderDash: [1, 3],
          color: "#e8e8e8",
        },
        ticks: {
          font: { size: 12 },
          padding: 1,
        },
      },
      y: {
        title: {
          display: true,
          text: "Grants",
        },
        grid: {
          borderDash: [1, 3],
          color: "#e8e8e8",
        },
        ticks: {
          font: { size: 12 },
        },
      },
    },
  };
  const data = useMemo(() => {
    return {
      labels: vestingScheduleTemplate?.map((vestSchedule) =>
        formatDisplayDate(new Date(vestSchedule.date))
      ),
      datasets: [
        {
          label: "Exercised",
          data: vestingScheduleTemplate.map((exercised) =>
            isFractional
              ? parseFloat(exercised.totalTransactedOptions.toFixed(4))
              : exercised.totalTransactedOptions
          ),
          backgroundColor: "rgb(80,205,137)",
        },
        {
          label: "Vested",
          data: vestingScheduleTemplate.map((vested) =>
            isFractional
              ? parseFloat(vested.currentVested.toFixed(4))
              : vested.currentVested
          ),
          backgroundColor: "rgb(248,177,149)",
        },
        {
          label: "Net Vested",
          data: vestingScheduleTemplate.map((vested) =>
            isFractional
              ? parseFloat(vested.netVested.toFixed(4))
              : vested.netVested
          ),
          backgroundColor: "rgb(118, 114, 246)",
        },
        {
          label: "Granted",
          data: vestingScheduleTemplate.map((granted) =>
            isFractional
              ? parseFloat(granted.totalGranted.toFixed(4))
              : granted.totalGranted
          ),
          backgroundColor: "rgb(246,114,128)",
        },
        {
          label: "Surrendered",
          data: vestingScheduleTemplate.map((granted) =>
            ["LAPSED", "SURRENDERED"].includes(granted.transactionType)
              ? isFractional
                ? parseFloat(granted.options.toFixed(4))
                : granted.options
              : 0
          ),
          backgroundColor: "rgb(246, 169, 114)",
        },
      ],
    };
  }, [vestingScheduleTemplate]);
  return (
    <Box className="p-4 bg-white ">
      <HStack className="justify-between">
        <VStack>
          <p className="font-semibold">Vesting Schedule</p>
        </VStack>

        {!showTable ? (
          <button
            onClick={() => {
              setShowTable(!showTable);
            }}
          >
            <Icon
              className="hover:text-orange-501"
              icon="mdi:table"
              height={25}
            />
          </button>
        ) : (
          <button
            onClick={() => {
              setShowTable(!showTable);
            }}
          >
            <Icon
              icon="mdi:graph-bar"
              className="hover:text-orange-501"
              height={25}
            />
          </button>
        )}
      </HStack>
      <Box className="mt-6 overflow-y-auto min-h-96">
        {showTable ? (
          <AGGridEmployeeScheduleTable
            currency={currency}
            isFractional={isFractional}
            data={vestingScheduleTemplate || []}
          />
        ) : (
          <Bar options={options} data={data} />
        )}
      </Box>
    </Box>
  );
};

function AGGridEmployeeScheduleTable(props: {
  data: EmployeeGrantTransactionDetail[];
  currency: string;
  isFractional: boolean;
}) {
  const { data, currency, isFractional } = props;
  const employeeVestingData = useMemo(() => {
    if (data) {
      return data;
    }
    return [];
  }, [data]);
  const [pinnedRowData, setPinnedRowData] = useState([{ transactionType: "" }]);
  const grandTotalRow = "bottom";
  const gridApi = useRef<GridApi | any>(null);
  const onGridReady = (params: any) => {
    gridApi.current = params;
    recalculateTotalRow();
  };

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      wrapText: true,
      flex: 1,
      autoHeight: true,
      initialWidth: 150,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      menuTabs: ["filterMenuTab"],
    }),
    []
  );
  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "DATE",
        field: "date",
        sortable: true,
        autoHeight: true,
        wrapText: true,
        minWidth: 200,
        width: 200,
        filterValueGetter: (e) => {
          return new Date(e.getValue("date")).getDate();
        },
        filter: "agDateColumnFilter",
        suppressAutoSize: false,
        suppressSizeToFit: true,
        valueFormatter: (e) =>
          e.value !== "TOTAL" ? formatDisplayDate(e.value) : e.value,
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: "GRANT ID",
        field: "identifier",
        autoHeight: true,
        wrapText: true,
        filter: "agSetColumnFilter",
        menuTabs: ["filterMenuTab"],
        sortable: true,
      },
      {
        headerName: "GRANTED",
        field: "totalGranted",
        autoHeight: true,
        wrapText: true,
        sortable: true,
        filter: "agNumberColumnFilter",
        valueFormatter: (e) =>
          getFormattedValue(e.value, currency, isFractional),
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: "No. of Options",
        field: "options",
        autoHeight: true,
        wrapText: true,
        sortable: true,
        valueFormatter: (e) =>
          getFormattedValue(e.value, currency, isFractional),
        filter: "agNumberColumnFilter",
      },
      {
        headerName: "Transaction Type",
        field: "transactionType",
        autoHeight: true,
        wrapText: true,
        sortable: true,
        cellRenderer: (param: any) => {
          return (
            <TransactionLabel
              state={param.data?.transactionType?.replace("_", " ")}
            />
          );
        },
        filterValueGetter: (param) =>
          param.data?.transactionType?.replace("_", " "),
        // valueFormatter: (param) => param.value?.replace("_", " "),
        filter: "agSetColumnFilter",
      },
      {
        headerName: "NET VESTED (Cumulative)",
        field: "netVested",
        autoHeight: true,
        wrapText: true,
        sortable: true,
        valueFormatter: (e) =>
          getFormattedValue(e.value, currency, isFractional),
        filter: "agNumberColumnFilter",
      },
    ],
    []
  );
  const recalculateTotalRow = () => {
    if (gridApi.current) {
      const rowData: EmployeeGrantTransactionDetail[] = [];
      gridApi.current.api.forEachNodeAfterFilterAndSort(
        (node: { data: EmployeeGrantTransactionDetail }) => {
          if (node.data) {
            rowData.push(node.data);
          }
        }
      );
      const totalRow: EmployeeGrantTransactionDetail = {
        currentVested: _.sumBy(rowData, (data) => data.currentVested),
        date: "TOTAL",
        transactionType: "TOTAL",
        options: 0,
        netCashedOut:
          _.maxBy(rowData, (data) => data.netCashedOut)?.netCashedOut || 0,
        totalTransactedOptions: 0,
        netStockExercised: 0,
        identifier: `${new Set(rowData.map((data) => data.identifier)).size}`,
        netVested: 0,
        totalGranted:
          _.maxBy(
            rowData.filter((data) => data.transactionType === "GRANTED"),
            (data) => data.totalGranted
          )?.totalGranted || 0,
      };
    }
  };
  return (
    <HStack className="justify-between w-full ">
      <Box
        style={{
          height: `${Math.min(
            400,
            (employeeVestingData.length >= 10
              ? 10
              : employeeVestingData.length + 3) * 60
          )}px`,
        }}
        className="w-full h-full max-h-full overflow-x-auto bg-black ag-theme-material"
      >
        <AgGridReact
          rowData={employeeVestingData}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          rowClass={"border-t border-dashed cursor-pointer hover:bg-slate-50 "}
          getRowStyle={(params) => {
            const isRowPinned = params?.node?.isRowPinned();
            return {
              background: isRowPinned ? "#f0f0f0" : "",
              fontWeight: isRowPinned ? "normal" : "normal",
              color: isRowPinned ? "black" : "",
            };
          }}
          onGridReady={onGridReady}
        />
      </Box>
    </HStack>
  );
}

const TransactionLabel = (props: { state: string }) => {
  const { state } = props;
  const transactionLabelColor = "";
  const getLabelStyle = () => {
    switch (state?.toLowerCase()) {
      case "granted": {
        return {
          bg: "rgb(232, 255, 243)",
          text: "rgb(122, 208, 150)",
        };
      }
      case "vested": {
        return {
          bg: "rgb(230, 245, 255)",
          text: "rgb(80, 175, 245)",
        };
      }
      case "yet to vest": {
        return {
          bg: "rgb(255, 248, 230)",
          text: "rgb(245, 184, 50)",
        };
      }
      case "surrendered": {
        return {
          bg: "rgb(255, 235, 230)",
          text: "rgb(245, 108, 108)",
        };
      }
      case "lapsed": {
        return {
          bg: "rgb(245, 245, 245)",
          text: "rgb(150, 150, 150)",
        };
      }
      case "cashed_out": {
        return {
          bg: "rgb(230, 245, 255)",
          text: "rgb(80, 175, 245)",
        };
      }
      case "stock_exercised": {
        return {
          bg: "rgb(240, 230, 255)",
          text: "rgb(160, 120, 245)",
        };
      }
      default: {
        return {
          bg: "rgb(232, 255, 243)",
          text: "rgb(122, 208, 150)",
        };
      }
    }
  };
  const { bg, text } = getLabelStyle();
  return (
    <HStack className="flex h-6 capitalize min-w-10 rounded-sm font-medium text-[9px] leading-4 px-2 mt-4">
      <p
        className="w-full h-full text-center justify-center content-center"
        style={{ color: text, backgroundColor: bg }}
      >
        {state}
      </p>
    </HStack>
  );
};

export default VestingSchedule;
